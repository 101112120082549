import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {SettingsIcon, StudioBackIconButton} from "../NewStudio/SettingsIcon";
import {
    LibrettoStudioTheme,
    RecordingModeString, RefreshTokenAndRetry,
    SecondaryRecordingStreamType,
    UploadWithSignedUrl
} from "../../utils/utils";
import Tooltip from "@mui/material/Tooltip";
import {NewRecordingStoppedCard, ScreenRecordingStoppedCard} from "../Studio/RecordingStoppedCard";
import ScreenRecordingSettingsCard from "../ScreenRecord/ScreenRecordingSettings";
import {AudioInputSelector, ScreenShareButton, VideoInputSelector} from "../ScreenRecord/CustomSelect";
import {
    AdjustWebcamPosition,
    AspectRatios,
    ScreenRecordButton,
    StartMicrophone,
    StartScreenCapture,
    StartWebcam,
    StopCapture
} from "../ScreenRecord/ScreenHelpers";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {FetchContext} from "../../context/FetchContext";
import {AuthContext} from "../../context/AuthContext";
import CustomRecordRTC from "../../components/CustomRecordRTC";
import RecordRTC, {StereoAudioRecorder} from "recordrtc";
import mixpanel from "mixpanel-browser";
import SignInUserIntercomComponent from "../../components/IntercomComponent";

var isEdge = navigator.userAgent.indexOf('Edge') !== -1 && (!!navigator.msSaveBlob || !!navigator.msSaveOrOpenBlob);
var isOpera = !!window.opera || navigator.userAgent.indexOf('OPR/') !== -1;
var isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1 && ('netscape' in window) && / rv:/.test(navigator.userAgent);
var isChrome = (!isOpera && !isEdge && !!navigator.webkitGetUserMedia) || navigator.userAgent.toLowerCase().indexOf('chrome/') !== -1;

var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

if (isSafari && !isChrome && navigator.userAgent.indexOf('CriOS') !== -1) {
    isSafari = false;
    isChrome = true;
}

function mergeLeftRightBuffers(config, callback) {
    function mergeAudioBuffers(config, cb) {
        var numberOfAudioChannels = config.numberOfAudioChannels;

        // todo: "slice(0)" --- is it causes loop? Should be removed?
        var leftBuffers = config.leftBuffers.slice(0);
        var rightBuffers = config.rightBuffers.slice(0);
        var sampleRate = config.sampleRate;
        var internalInterleavedLength = config.internalInterleavedLength;
        var desiredSampRate = config.desiredSampRate;

        if (numberOfAudioChannels === 2) {
            leftBuffers = mergeBuffers(leftBuffers, internalInterleavedLength);
            rightBuffers = mergeBuffers(rightBuffers, internalInterleavedLength);
            if (desiredSampRate) {
                leftBuffers = interpolateArray(leftBuffers, desiredSampRate, sampleRate);
                rightBuffers = interpolateArray(rightBuffers, desiredSampRate, sampleRate);
            }
        }

        if (numberOfAudioChannels === 1) {
            leftBuffers = mergeBuffers(leftBuffers, internalInterleavedLength);
            if (desiredSampRate) {
                leftBuffers = interpolateArray(leftBuffers, desiredSampRate, sampleRate);
            }
        }

        // set sample rate as desired sample rate
        if (desiredSampRate) {
            sampleRate = desiredSampRate;
        }

        // for changing the sampling rate, reference:
        // http://stackoverflow.com/a/28977136/552182
        function interpolateArray(data, newSampleRate, oldSampleRate) {
            var fitCount = Math.round(data.length * (newSampleRate / oldSampleRate));
            //var newData = new Array();
            var newData = [];
            //var springFactor = new Number((data.length - 1) / (fitCount - 1));
            var springFactor = Number((data.length - 1) / (fitCount - 1));
            newData[0] = data[0]; // for new allocation
            for (var i = 1; i < fitCount - 1; i++) {
                var tmp = i * springFactor;
                //var before = new Number(Math.floor(tmp)).toFixed();
                //var after = new Number(Math.ceil(tmp)).toFixed();
                var before = Number(Math.floor(tmp)).toFixed();
                var after = Number(Math.ceil(tmp)).toFixed();
                var atPoint = tmp - before;
                newData[i] = linearInterpolate(data[before], data[after], atPoint);
            }
            newData[fitCount - 1] = data[data.length - 1]; // for new allocation
            return newData;
        }

        function linearInterpolate(before, after, atPoint) {
            return before + (after - before) * atPoint;
        }

        function mergeBuffers(channelBuffer, rLength) {
            var result = new Float64Array(rLength);
            var offset = 0;
            var lng = channelBuffer.length;

            for (var i = 0; i < lng; i++) {
                var buffer = channelBuffer[i];
                result.set(buffer, offset);
                offset += buffer.length;
            }

            return result;
        }

        function interleave(leftChannel, rightChannel) {
            var length = leftChannel.length + rightChannel.length;

            var result = new Float64Array(length);

            var inputIndex = 0;

            for (var index = 0; index < length;) {
                result[index++] = leftChannel[inputIndex];
                result[index++] = rightChannel[inputIndex];
                inputIndex++;
            }
            return result;
        }

        function writeUTFBytes(view, offset, string) {
            var lng = string.length;
            for (var i = 0; i < lng; i++) {
                view.setUint8(offset + i, string.charCodeAt(i));
            }
        }

        // interleave both channels together
        var interleaved;

        if (numberOfAudioChannels === 2) {
            interleaved = interleave(leftBuffers, rightBuffers);
        }

        if (numberOfAudioChannels === 1) {
            interleaved = leftBuffers;
        }

        var interleavedLength = interleaved.length;

        // create wav file
        var resultingBufferLength = 44 + interleavedLength * 2;

        var buffer = new ArrayBuffer(resultingBufferLength);

        var view = new DataView(buffer);

        // RIFF chunk descriptor/identifier
        writeUTFBytes(view, 0, 'RIFF');

        // RIFF chunk length
        view.setUint32(4, 44 + interleavedLength * 2, true);

        // RIFF type
        writeUTFBytes(view, 8, 'WAVE');

        // format chunk identifier
        // FMT sub-chunk
        writeUTFBytes(view, 12, 'fmt ');

        // format chunk length
        view.setUint32(16, 16, true);

        // sample format (raw)
        view.setUint16(20, 1, true);

        // stereo (2 channels)
        view.setUint16(22, numberOfAudioChannels, true);

        // sample rate
        view.setUint32(24, sampleRate, true);

        // byte rate (sample rate * block align)
        view.setUint32(28, sampleRate * 2, true);

        // block align (channel count * bytes per sample)
        view.setUint16(32, numberOfAudioChannels * 2, true);

        // bits per sample
        view.setUint16(34, 16, true);

        // data sub-chunk
        // data chunk identifier
        writeUTFBytes(view, 36, 'data');

        // data chunk length
        view.setUint32(40, interleavedLength * 2, true);

        // write the PCM samples
        var lng = interleavedLength;
        var index = 44;
        var volume = 1;
        for (var i = 0; i < lng; i++) {
            view.setInt16(index, interleaved[i] * (0x7FFF * volume), true);
            index += 2;
        }

        if (cb) {
            return cb({
                buffer: buffer,
                view: view
            });
        }

        postMessage({
            buffer: buffer,
            view: view
        });
    }

    if (!isChrome) {
        // its Microsoft Edge
        mergeAudioBuffers(config, function (data) {
            callback(data.buffer, data.view);
        });
        return;
    }


    var webWorker = processInWebWorker(mergeAudioBuffers);

    webWorker.onmessage = function (event) {
        callback(event.data.buffer, event.data.view);

        // release memory
        URL.revokeObjectURL(webWorker.workerURL);
    };

    webWorker.postMessage(config);
}

function processInWebWorker(_function) {
    var workerURL = URL.createObjectURL(new Blob([_function.toString(),
        ';this.onmessage =  function (eee) {' + _function.name + '(eee.data);}'
    ], {
        type: 'application/javascript'
    }));

    var worker = new Worker(workerURL);
    worker.workerURL = workerURL;
    return worker;
}

const messageStyle = (backgroundColor) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.1)', // Consider making this dynamic or inviting
    color: "white",
    fontSize: '18px',
    textAlign: 'center',
    padding: '30px', // Increase padding for space around content
});

const countdownBoxStyle = {
    position: "absolute",
    height: "100vh",
    width: "100vw",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "rgba(26, 26, 26, 0.15)",
    zIndex: 1000,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
}

const countdownTextStyle = {
    color: "#2B6BFD",
    fontSize: "120px",
    fontStyle: "normal",
    fontWeight: "800",
    lineHeight: "normal",
    opacity: 1,
    zIndex: 999999,
    fontFamily: "Inter, sans-serif",  // Added fallback font
}

const pageStyle = {
    height: "100vh",
    width: "100vw",
    backgroundColor: "#f3f4f5",
}

const videoAndControlsAreaStyle = {
    display: "flex",
    flexDirection: "column",
    width: "calc(100vw - 140px)",
    height: "calc(100vh - 30px)",
    // border: "1px solid black",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "50px",
    marginRight: "50px",
    marginTop: "20px",
    marginBottom: "20px",
}

const videoAreaStyle = {
    width: '100%',
    flex: 1,
    backgroundColor: 'transparent',
    borderRadius: '8px',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const controlBarStyle = {
    height: "93px",
    width: "auto",
    paddingLeft: "22px",
    paddingRight: "22px",
    marginBottom: "5px",
    gap: "12px",
    alignItems: "center",
    display: "inline-flex",
    backgroundColor: "#ffffff",
    borderRadius: "12px",
    boxShadow: "0px 20px 75px 0px rgba(0, 0, 0, 0.05)",
}

const getVideoStyle = (displayMode) => {
    const baseStyle = {
        width: '100%',
        height: '100%',
    };

    if (displayMode === 'fit') {
        return {
            ...baseStyle,
            objectFit: 'contain',
        };
    } else {
        return {
            ...baseStyle,
            objectFit: 'cover',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        };
    }
};


const RecordScreen = () => {
    const navigate = useNavigate();

    const {projectId} = useParams();

    const [screenStream, setScreenStream] = useState(null);
    const [webcamStream, setWebcamStream] = useState(null);
    const [micStream, setMicStream] = useState(null);
    const [isMovingWebcam, setIsMovingWebcam] = useState(false);
    const [webcamPosition, setWebcamPosition] = useState({x: 20, y: 20});
    const [aspectRatio, setAspectRatio] = useState('16:9');
    const [displayMode, setDisplayMode] = useState('fit');
    const [webcamSize, setWebcamSize] = useState(225);
    const [backgroundColor, setBackgroundColor] = useState('#FFFFFF');
    const [webcamDevices, setWebcamDevices] = useState([]);
    const [micDevices, setMicDevices] = useState([]);
    const screenVideoRef = useRef(null);
    const webcamVideoRef = useRef(null);
    const containerRef = useRef(null);
    const videoContainerRef = useRef(null);

    const [isWebcamEnabled, setIsWebcamEnabled] = useState(false);
    const [isMicEnabled, setIsMicEnabled] = useState(false);

    const [recordingStopped, setRecordingStopped] = useState(false);
    const [screenRecordingDone, setScreenRecordingDone] = useState(false);
    const [screenRecordingUploadProgress, setScreenRecordingUploadProgress] = useState(0);
    const [secondaryRecordingDone, setSecondaryRecordingDone] = useState(false);
    const [secondaryRecordingUploadProgress, setSecondaryRecordingUploadProgress] = useState(0);
    const [showRecordingEndedCard, setShowRecordingEndedCard] = useState(true);

    const [showControls, setShowControls] = useState(false);

    const [dimensions, setDimensions] = useState({width: '100%', height: '100%'});

    const audioContextRef = useRef(null);
    const mixedDestinationRef = useRef(null);

    const secondaryRecordingStreamTypeRef = useRef("None");

    const [recordingStarted, setRecordingStarted] = useState(false);
    const screenRecorderRef = useRef(null);
    const cameraMicrophoneRecorderRef = useRef(null);
    const [recordingInProgress, setRecordingInProgress] = useState(false);
    const assetIdRef = useRef(null);
    const screenRecordingTrackIdRef = useRef(null);
    const secondaryRecordingTrackIdRef = useRef(null);
    const screenRecordingSignedUrl = useRef(null);
    const secondaryRecordingSignedUrl = useRef(null);

    const fetchContext = useContext(FetchContext);
    const auth = useContext(AuthContext);

    // Prepare audio context and mixed destination, and connect the microphone stream.
    useEffect(() => {
        const newAudioContext = new AudioContext({sampleRate: 48000, latencyHint: "playback"});
        const newMixedDestination = newAudioContext.createMediaStreamDestination();
        audioContextRef.current = newAudioContext;
        mixedDestinationRef.current = newMixedDestination;

        return () => {
            if (newAudioContext !== null && newAudioContext.state !== "closed") {
                newAudioContext.close();
            }
        };
    }, []);

    useEffect(() => {
        if (!isMicEnabled || !micStream) return;

        const sourceNode = audioContextRef.current.createMediaStreamSource(micStream);
        sourceNode.connect(mixedDestinationRef.current);

    }, [isMicEnabled, micStream]);

    const navigateToDashboard = () => {
        navigate("/dashboard");
    }

    const CalculateDimensions = useCallback(() => {
        if (!containerRef.current) return {width: '100%', height: '100%'};
        const containerHeight = containerRef.current.offsetHeight;
        const containerWidth = containerRef.current.offsetWidth;
        const ratio = AspectRatios[aspectRatio];
        let height = containerHeight;
        let width = (height * ratio.width) / ratio.height;
        if (width > containerWidth) {
            width = containerWidth;
            height = (width * ratio.height) / ratio.width;
        }
        return {width: `${width}px`, height: `${height}px`};
    }, [aspectRatio]);

    useEffect(() => {
        const updateDimensions = () => {
            const newDimensions = CalculateDimensions();
            setDimensions(newDimensions);
            AdjustWebcamPosition({newDimensions, webcamPosition, setWebcamPosition});
        };
        updateDimensions();
        window.addEventListener('resize', updateDimensions);
        return () => window.removeEventListener('resize', updateDimensions);
    }, [aspectRatio, CalculateDimensions]);

    useEffect(() => {
        const getDevices = async () => {
            const devices = await navigator.mediaDevices.enumerateDevices();
            setWebcamDevices(devices.filter(device => device.kind === 'videoinput'));
            setMicDevices(devices.filter(device => device.kind === 'audioinput'));
        };
        getDevices();
    }, []);

    const handleMouseDown = (e) => {
        setIsMovingWebcam(true);
    };

    const getRenderedVideoDimensions = () => {
        if (screenVideoRef.current) {
            const videoElement = screenVideoRef.current;

            // Get the container dimensions
            const containerRect = videoElement.getBoundingClientRect();
            const containerWidth = containerRect.width;
            const containerHeight = containerRect.height;

            // Get the natural dimensions (intrinsic width and height) of the video
            const videoWidth = videoElement.videoWidth;
            const videoHeight = videoElement.videoHeight;

            // Calculate aspect ratio of the video
            const videoAspectRatio = videoWidth / videoHeight;
            const containerAspectRatio = containerWidth / containerHeight;

            let renderedWidth, renderedHeight;

            // If the container is wider than the video (relative to their aspect ratios)
            if (containerAspectRatio > videoAspectRatio) {
                // The height of the container is fully used
                renderedHeight = containerHeight;
                // Calculate the width using the video's aspect ratio
                renderedWidth = containerHeight * videoAspectRatio;
            } else {
                // The width of the container is fully used
                renderedWidth = containerWidth;
                // Calculate the height using the video's aspect ratio
                renderedHeight = containerWidth / videoAspectRatio;
            }

            // Calculate the offset from the container for centering
            const offsetX = (containerWidth - renderedWidth) / 2;
            const offsetY = (containerHeight - renderedHeight) / 2;

            return {
                width: renderedWidth,
                height: renderedHeight,
                offsetX,
                offsetY,
                containerRect
            };
        }
        return null;
    };

    const handleMouseMove = (e) => {
        if (isMovingWebcam && videoContainerRef.current && !recordingStarted) {
            if (!screenVideoRef.current) {
                const containerRect = videoContainerRef.current.getBoundingClientRect();
                const newX = e.clientX - containerRect.left - webcamSize / 2;
                const newY = e.clientY - containerRect.top - webcamSize / 2;
                setWebcamPosition({
                    x: Math.max(0, Math.min(newX, containerRect.width - webcamSize)),
                    y: Math.max(0, Math.min(newY, containerRect.height - webcamSize)),
                });
                return;
            }

            const containerRect = videoContainerRef.current.getBoundingClientRect(); // Overlay container (main video container)
            const renderedVideoDimensions = getRenderedVideoDimensions(); // Get the rendered video dimensions

            if (renderedVideoDimensions) {
                const { width: videoWidth, height: videoHeight, offsetX, offsetY, containerRect } = renderedVideoDimensions;

                // Calculate the new positions of the webcam
                const newX = e.clientX - containerRect.left - webcamSize / 2;
                const newY = e.clientY - containerRect.top - webcamSize / 2;

                // Ensure webcam overlay is fully contained within the actual video (not the container)
                const adjustedX = Math.max(
                    offsetX, // Left boundary
                    Math.min(newX, offsetX + videoWidth - webcamSize) // Right boundary
                );

                const adjustedY = Math.max(
                    offsetY, // Top boundary
                    Math.min(newY, offsetY + videoHeight - webcamSize) // Bottom boundary
                );

                // Update the webcam position
                setWebcamPosition({
                    x: adjustedX,
                    y: adjustedY,
                });
            }
        }
    };

    const handleMouseUp = () => {
        setIsMovingWebcam(false);
    };

    useEffect(() => {
        document.addEventListener('mouseup', handleMouseUp);
        document.addEventListener('mousemove', handleMouseMove);
        return () => {
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('mousemove', handleMouseMove);
        };
    }, [isMovingWebcam]);

    useEffect(() => {
        if (screenStream && screenVideoRef.current) {
            screenVideoRef.current.srcObject = screenStream;
        }
        if (webcamStream && webcamVideoRef.current) {
            webcamVideoRef.current.srcObject = webcamStream;
        }
    }, [screenStream, webcamStream]);

    const theme = useTheme();

    const isXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));

    const buttonHeight = isXl ? "40px" : (isLg ? "36px" : "34px");


    const handleScreenShareClick = () => {
        if (screenStream) {
            StopCapture({
                streamType: 'screen',
                webcamStream,
                micStream,
                screenStream,
                setWebcamStream,
                setMicStream,
                setScreenStream
            });
        } else {
            StartScreenCapture({setScreenStream});
        }
    }

    const [countdown, setCountdown] = useState(null);

    const settingsButtonDisabled = recordingStarted;

    const handleNavigateToAsset = () => {
        navigate("/assets/" + assetIdRef.current);
    }

    useEffect(() => {
        if (recordingStarted && countdown === null) {
            setRecordingInProgress(true);
        }
    }, [recordingStarted, countdown]);

    const translateToVideoCoordinates = (webcamPosition, webcamSize) => {
        if (screenVideoRef.current && screenStream) {
            const actualVideoWidth = screenStream.getVideoTracks()[0].getSettings().width;
            const actualVideoHeight = screenStream.getVideoTracks()[0].getSettings().height;

            // Get the rendered video dimensions
            const renderedVideoDimensions = getRenderedVideoDimensions();
            if (renderedVideoDimensions) {
                const { width: renderedWidth, height: renderedHeight, offsetX, offsetY } = renderedVideoDimensions;

                // Calculate the scale factor between the rendered video and actual video
                const scaleX = actualVideoWidth / renderedWidth;
                const scaleY = actualVideoHeight / renderedHeight;

                // Translate x, y coordinates to be relative to the actual video
                const videoX = (webcamPosition.x - offsetX) * scaleX;
                const videoY = (webcamPosition.y - offsetY) * scaleY;

                // Scale the webcam size to be relative to the actual video size
                const avgScale = (scaleX + scaleY) / 2;
                const webcamWidth = webcamSize * avgScale;
                const webcamHeight = webcamWidth;

                return {
                    x: videoX,
                    y: videoY,
                    width: webcamWidth,
                    height: webcamHeight,
                };
            }
        }
        return null;
    };


    const StartScreenRecording = async ({secondaryStreamType}) => {
        const {x, y, width, height} = translateToVideoCoordinates(webcamPosition, webcamSize);

        try {
            const response = await fetchContext.authAxios.post('/record/screen', {
                projectId: projectId,
                title: 'My screen recording',
                secondaryStreamType: secondaryStreamType,
                secondaryVideoXPosition: Math.round(x),
                secondaryVideoYPosition: Math.round(y),
                secondaryVideoWidth: Math.round(width),
                secondaryVideoHeight: Math.round(height),
            }, {
                headers: {
                    Authorization: `Bearer ${auth.getToken()}`,
                }
            });
            mixpanel.track('Screen recording started', {
                'assetId': response.data.assetId,
            });
            assetIdRef.current = response.data.assetId;
            screenRecordingTrackIdRef.current = response.data.screenRecordingTrackId;
            secondaryRecordingTrackIdRef.current = response.data.secondaryRecordingTrackId;
            screenRecordingSignedUrl.current = response.data.signedUrl;
            secondaryRecordingSignedUrl.current = response.data.secondaryRecordingSignedUrl;
            console.log("Url: ", screenRecordingSignedUrl)
            console.log("Secondary url: ", secondaryRecordingSignedUrl)

        } catch (error) {
            console.log("Error starting recording: ", error)
            if (error.response.status === 401) {
                await RefreshTokenAndRetry(error, auth, fetchContext);
            }
        }
    };


    const StartRecording = async () => {
        setCountdown(5);
        setRecordingStopped(false);
        secondaryRecordingStreamTypeRef.current = SecondaryRecordingStreamType({webcamStream, micStream});

        await StartScreenRecording({secondaryStreamType: secondaryRecordingStreamTypeRef.current});

        const videoTrack = screenStream.getVideoTracks()[0];
        const settings = videoTrack.getSettings();

        screenRecorderRef.current = new CustomRecordRTC(screenStream, {
            type: 'video',
            mimeType: 'video/x-matroska;codecs=avc1',
            videoBitsPerSecond: 10000000,
        });

        if (webcamStream && micStream) {
            cameraMicrophoneRecorderRef.current = new CustomRecordRTC(new MediaStream([webcamStream.getVideoTracks()[0], micStream.getAudioTracks()[0]]), {
                type: 'video',
                mimeType: 'video/x-matroska;codecs=avc1',
                videoBitsPerSecond: 10000000,
            });
        } else if (webcamStream) {
            cameraMicrophoneRecorderRef.current = new CustomRecordRTC(new MediaStream([webcamStream.getVideoTracks()[0]]), {
                type: 'video',
                mimeType: 'video/x-matroska;codecs=avc1',
                videoBitsPerSecond: 10000000,
            });
        } else if (micStream) {
            cameraMicrophoneRecorderRef.current = RecordRTC(micStream, {
                type: 'audio',
                recorderType: StereoAudioRecorder,
            });
        }

        screenRecorderRef.current.startRecording();
        if (cameraMicrophoneRecorderRef.current) {
            cameraMicrophoneRecorderRef.current.startRecording();
        }
        setRecordingStarted(true)
    }

    const StopRecording = () => {
        if (!recordingStarted) {
            return;
        }

        setRecordingInProgress(false);
        setRecordingStarted(false);
        setRecordingStopped(true);

        if (screenRecorderRef.current) {
            screenRecorderRef.current.stopRecording(() => {

                const blob = screenRecorderRef.current.getBlob();

                UploadWithSignedUrl({
                    blob,
                    signedUrl: screenRecordingSignedUrl.current,
                    setProgress: setScreenRecordingUploadProgress,
                    setRecordingDone: setScreenRecordingDone,
                    isVideo: true,
                });
            });
        }

        if (cameraMicrophoneRecorderRef.current) {
            cameraMicrophoneRecorderRef.current.stopRecording(() => {
                if (secondaryRecordingStreamTypeRef.current === "Audio") {
                    var internalRecorder = cameraMicrophoneRecorderRef.current.getInternalRecorder();
                    var leftchannel = internalRecorder.leftchannel;
                    var rightchannel = internalRecorder.rightchannel;

                    mergeLeftRightBuffers({
                        desiredSampRate: internalRecorder.desiredSampRate,
                        sampleRate: internalRecorder.sampleRate,
                        numberOfAudioChannels: internalRecorder.numberOfAudioChannels,
                        internalInterleavedLength: internalRecorder.recordingLength,
                        leftBuffers: leftchannel,
                        rightBuffers: internalRecorder.numberOfAudioChannels === 1 ? [] : rightchannel
                    }, function (buffer, view) {
                        // ------------------------------------------------------------
                        // here is your own WAV (generated by your own codes)
                        // ------------------------------------------------------------
                        const blob = new Blob([buffer], {
                            type: 'audio/wav'
                        });

                        UploadWithSignedUrl({
                            blob,
                            signedUrl: secondaryRecordingSignedUrl.current,
                            setProgress: setSecondaryRecordingUploadProgress,
                            setRecordingDone: setSecondaryRecordingDone,
                            isVideo: false,
                        });
                    });
                } else {
                    const blob = cameraMicrophoneRecorderRef.current.getBlob();

                    UploadWithSignedUrl({
                        blob,
                        signedUrl: secondaryRecordingSignedUrl.current,
                        setProgress: setSecondaryRecordingUploadProgress,
                        setRecordingDone: setSecondaryRecordingDone,
                        isVideo: true,
                    });
                }
            });
        } else {
            setSecondaryRecordingDone(true);
            setSecondaryRecordingUploadProgress(100);
        }
    }

    // Countdown before recording starts.
    useEffect(() => {
        let intervalId;
        if (countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setCountdown(null);
        }

        // Clear interval on unmount or countdown change
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [countdown]);

    const averageUploadProgress = () => {
        return (screenRecordingUploadProgress + secondaryRecordingUploadProgress) / 2;
    }

    // Try to start webcam and microphone on page load
    useEffect(() => {
        StartWebcam({
            deviceId: "",
            setIsWebcamEnabled,
            setWebcamStream,
            webcamStream
        })
        StartMicrophone({
            deviceId: "",
            setIsMicEnabled,
            setMicStream,
            micStream
        })
    }, []);


    return (
        <Grid container justifyContent="center" alignItems="center" style={pageStyle}>
            <SignInUserIntercomComponent user={auth.getUser()}/>
            <Box style={{top: "20px", left: "32px", position: "absolute"}}>
                <StudioBackIconButton handleClick={navigateToDashboard} theme={LibrettoStudioTheme.Light}/>
            </Box>
            {recordingStopped ? <ScreenRecordingStoppedCard uploadProgress={averageUploadProgress()}
                                                            showRecordingEndedCard={showRecordingEndedCard}
                                                            setShowRecordingEndedCard={setShowRecordingEndedCard}
                                                            onNavigate={handleNavigateToAsset}
                                                            screenRecordingDone={screenRecordingDone}
                                                            secondaryRecordingDone={secondaryRecordingDone}/> : null}
            <Grid item alignSelf="center">
                {countdown !== null && countdown > 0 && (<Box
                    style={countdownBoxStyle}
                >
                    <Box style={countdownTextStyle}
                    >
                        {countdown}
                    </Box>
                </Box>)}
            </Grid>
            <Box style={videoAndControlsAreaStyle}>
                <Box
                    ref={containerRef}
                    style={videoAreaStyle}
                >
                    <Box ref={videoContainerRef} style={{
                        ...dimensions,
                        position: 'relative',
                        overflow: 'hidden',
                        // border: "2px dashed #e8e8e8",
                        background: 'linear-gradient(135deg, #2b6bfd, #28a745)',
                        // borderRadius: '8px',
                    }}>
                        {!screenStream && (
                            <Box style={messageStyle(backgroundColor)}>
                                <Box>
                                    <h2 style={{ fontSize: '24px', marginBottom: '15px', color: "white" }}>
                                        🎥 Start Recording!
                                    </h2>
                                    <Box style={{ fontSize: '18px', marginBottom: '10px' }}>
                                        <strong>Share your screen and turn on your camera</strong> to record both at the same time.
                                    </Box>
                                    <Box style={{ fontSize: '16px', marginBottom: '10px' }}>
                                        <ul style={{ paddingLeft: '20px', textAlign: 'left' }}>
                                            <li>🎬 Record your screen with microphone audio.</li>
                                            <li>📷 Add, move and resize your webcam for picture-in-picture video.</li>
                                            <li>🔊 Capture audio from both your screen and microphone.</li>
                                        </ul>
                                    </Box>
                                    <Box style={{ fontSize: '16px', fontStyle: 'italic' }}>
                                        Get started by sharing your screen to record.
                                    </Box>
                                </Box>
                            </Box>
                        )}
                        {screenStream && (
                            <video
                                ref={screenVideoRef}
                                style={getVideoStyle(displayMode)}
                                autoPlay
                                playsInline
                            />
                        )}
                        {isWebcamEnabled && webcamStream && (
                            <Box
                                style={{
                                    position: 'absolute',
                                    left: `${webcamPosition.x}px`,
                                    top: `${webcamPosition.y}px`,
                                    width: `${webcamSize}px`,
                                    height: `${webcamSize}px`,
                                    borderRadius: '50%',
                                    overflow: 'hidden',
                                    border: '3px solid white',
                                    cursor: recordingStarted ? "not-allowed" : isMovingWebcam ? 'grabbing' : 'grab',
                                }}
                                onMouseDown={handleMouseDown}
                                onMouseEnter={() => setShowControls(true)} // Show controls on hover
                                onMouseLeave={() => setShowControls(false)} // Hide controls when not hovering
                            >
                                <video
                                    ref={webcamVideoRef}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'cover',
                                    }}
                                    autoPlay
                                    playsInline
                                    muted
                                />

                                {/* Button Area Overlay - Appear on hover */}
                                {showControls && !recordingStarted && (
                                    <Box
                                        style={{
                                            position: 'absolute',
                                            bottom: "2px", // Adjust positioning below the webcam feed
                                            left: '50%',
                                            transform: 'translateX(-50%)',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'space-between',
                                            width: '60px',
                                            height: '40px',
                                            backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
                                            borderRadius: '20px',
                                            padding: '5px 10px',
                                            zIndex: 10, // Ensure it appears above the video
                                            cursor: 'default'
                                        }}
                                        onClick={() => {}}
                                    >
                                        {/* First Button - Small Circle */}
                                        <Box
                                            onClick={() => setWebcamSize(150)} // Smaller circle when clicked
                                            style={{
                                                width: '10px',
                                                height: '10px',
                                                backgroundColor: webcamSize === 150 ? '#2b6Bfd' : 'white', // Highlight if selected
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                                border: webcamSize === 150 ? '2px solid white' : 'none', // Optional border for better visibility
                                            }}
                                        />
                                        {/* Second Button - Larger Circle */}
                                        <Box
                                            onClick={() => setWebcamSize(225)} // Larger circle when clicked
                                            style={{
                                                width: '16px',
                                                height: '16px',
                                                backgroundColor: webcamSize === 225 ? '#2b6Bfd' : 'white', // Highlight if selected
                                                borderRadius: '50%',
                                                cursor: 'pointer',
                                                border: webcamSize === 225 ? '2px solid white' : 'none', // Optional border for better visibility
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                        )}
                    </Box>
                </Box>
                <Grid container style={controlBarStyle} spacing={1} justifyContent={"center"} marginTop={2}>
                    <Grid item>
                        <AudioInputSelector enabled={isMicEnabled} options={micDevices.map(device => ({
                            value: device.deviceId,
                            label: device.label || `Mic ${device.deviceId.slice(0, 5)}`
                        }))} setEnabled={(val) => StartMicrophone({
                            deviceId: val ? "" : "disabled",
                            setIsMicEnabled,
                            setMicStream,
                            micStream
                        })} onDeviceChange={(deviceId) => StartMicrophone({
                            deviceId: deviceId,
                            setIsMicEnabled,
                            setMicStream,
                            micStream
                        })} disabled={recordingStarted}/>
                    </Grid>
                    <Grid item>
                        <VideoInputSelector setEnabled={(val) => StartWebcam({
                            deviceId: val ? "" : "disabled",
                            setIsWebcamEnabled,
                            setWebcamStream,
                            webcamStream
                        })} onDeviceChange={(deviceId) => StartWebcam({
                            deviceId: deviceId,
                            setIsWebcamEnabled,
                            setWebcamStream,
                            webcamStream
                        })} enabled={isWebcamEnabled} options={
                            webcamDevices.map(device => ({
                                value: device.deviceId,
                                label: device.label || `Webcam ${device.deviceId.slice(0, 5)}`
                            }))} disabled={recordingStarted}/>
                    </Grid>
                    <Grid item>
                        <ScreenShareButton enabled={screenStream} handleClick={handleScreenShareClick}/>
                    </Grid>
                    <Grid item>
                        {screenStream ?
                            <ScreenRecordButton recordingInProgress={recordingInProgress}
                                                startRecording={StartRecording}
                                                stopRecording={StopRecording}/> : null}
                    </Grid>
                </Grid>
                <div style={{
                    marginTop: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                    gap: '10px'
                }}>
                </div>
            </Box>
        </Grid>
    )
}

export default RecordScreen;