import React, {useState, useEffect, useCallback, useContext} from 'react';
import {IMAGES_PATH_CDN, TitleDescription} from "../../../../config/config";
import {ApplicationStore} from "../../../../store/ApplicationStore";
import {RendleyService} from "../../../../services/RendleyService";
import {RendleyStore} from "../../../../store/RendleyStore";
import PanelGridListSection from '../../../../components/PanelGridListSection/PanelGridListSection';
import Draggable from '../../../../components/Draggable/Draggable';
import EffectShowcaseCard from '../../../../components/EffectsShowcaseCard/EffectShowcaseCard';
import './TextPanelContainer.styles.scss';
import Panel from "../../components/Panel/Panel";
import {FetchContext} from "../../../../../context/FetchContext";

const TITLES_DATA = {
    "titles": [
        {
            "id": "RenderTile1",
            "name": "RenderTile1",
            "path": "RenderTile1/",
            "duration": "8"
        },
        {
            "id": "RenderTile2",
            "name": "RenderTile2",
            "path": "RenderTile2/",
            "duration": "8"
        },
        {
            "id": "RenderTile3",
            "name": "RenderTile3",
            "path": "RenderTile3/",
            "duration": "8"
        },
        {
            "id": "RenderTile4",
            "name": "RenderTile4",
            "path": "RenderTile4/",
            "duration": "8"
        },
        {
            "id": "RenderTile5",
            "name": "RenderTile5",
            "path": "RenderTile5/",
            "duration": "8"
        },
        {
            "id": "RenderTile6",
            "name": "RenderTile6",
            "path": "RenderTile6/",
            "duration": "8"
        },
        {
            "id": "RenderTile7",
            "name": "RenderTile7",
            "path": "RenderTile7/",
            "duration": "8"
        },
        {
            "id": "RenderTile8",
            "name": "RenderTile8",
            "path": "RenderTile8/",
            "duration": "8"
        },
        {
            "id": "RenderTile9",
            "name": "RenderTile9",
            "path": "RenderTile9/",
            "duration": "8"
        },
        {
            "id": "RenderTile10",
            "name": "RenderTile10",
            "path": "RenderTile10/",
            "duration": "8"
        },
        {
            "id": "RenderTile11",
            "name": "RenderTile11",
            "path": "RenderTile11/",
            "duration": "8"
        },
        {
            "id": "RenderTile12",
            "name": "RenderTile12",
            "path": "RenderTile12/",
            "duration": "8"
        }
    ]
};

const TextPanelContainer = () => {
    const [data, setData] = useState([]);

    const fetchContext = useContext(FetchContext);

    const getProxiedURL = fetchContext.getProxiedURL;

    useEffect(() => {
        init();
    }, []);

    const init = () => {
        const titlesRoot = ApplicationStore.titlesPath;

        const titlesJsonUrl = getProxiedURL(titlesRoot + "titles.json");

        fetch(titlesJsonUrl)
            .then((data) => data.json())
            .then((data) => {
                const titles: TitleDescription[] = data.titles.map((title: any) => {
                    return {
                        id: title.id,
                        name: title.name,
                        thumbnailUrl: titlesRoot + title.path + "thumbnail.webp",
                        videoPreviewUrl: titlesRoot + title.path + "preview.mp4",
                        propertiesUrl: titlesRoot + title.path + "properties.json",
                        duration: parseInt(title.duration),
                        dataUrl: titlesRoot + title.path + "data.json",
                    };
                });

                setData(titles);
            });
    };

    const handleDragStart = useCallback((payload: any) => {
        ApplicationStore.setDraggingObject({
            type: "text-preset",
            payload,
        });
    }, []);

    const handleDragEnd = useCallback(() => {
        ApplicationStore.setDraggingObject(null);
    }, []);

    // @ts-ignore
    const handleAddTitle = useCallback(async (title) => {
        const clip = await RendleyService.createLottieClip({
            dataUrl: title.dataUrl,
            propertiesUrl: title.propertiesUrl,
            startTime: RendleyStore.currentTime,
        });

        if (clip == null) {
            return;
        }

        ApplicationStore.setSelectedClipId(clip.id);
    }, []);

    const handleAddPlainText = useCallback(async () => {
        const clip = await RendleyService.createTextClip({text: "Text", startTime: RendleyStore.currentTime});

        if (clip == null) {
            return;
        }

        ApplicationStore.setSelectedClipId(clip.id);
    }, []);

    const thumbnailUrl = getProxiedURL(`${IMAGES_PATH_CDN}text.webp`);

    return (
        <div style={{width: "100%"}}>
            {/* @ts-ignore */}
            <PanelGridListSection
                data={[{name: "Plain", thumbnailUrl: thumbnailUrl}]}
                visibleItemsCount={1}
                renderCard={(item) => (
                    <Draggable onDragStart={() => handleDragStart(item)} onDragEnd={handleDragEnd} payload={{}}>
                        {/* @ts-ignore */}
                        <EffectShowcaseCard
                            name={item.name}
                            imageUrl={item.thumbnailUrl}
                            onMouseDown={handleAddPlainText}
                        />
                    </Draggable>
                )}
            />

            <div className="spacer-2"></div>

            {/* @ts-ignore */}
            <PanelGridListSection<{ name: string, thumbnailUrl: string, videoPreviewUrl: string }>
                title="Titles"
                data={data}
                visibleItemsCount={data.length}
                renderCard={(item) => (
                    <Draggable onDragStart={() => handleDragStart(item)} onDragEnd={handleDragEnd} payload={{}}>
                        {/* @ts-ignore */}
                        <EffectShowcaseCard
                            imageUrl={item.thumbnailUrl}
                            videoUrl={item.videoPreviewUrl}
                            onMouseDown={() => handleAddTitle(item)}
                        />
                    </Draggable>
                )}
            />
        </div>
    );
};

export default TextPanelContainer;