import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import {AuthContext} from "../../context/AuthContext";
import {useNavigate, useParams} from "react-router-dom";
import {collection, doc, onSnapshot, query, setDoc, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {
    AssetDeleted, AssetId, AssetProjectId, AssetType,
    AssetUserId, EditorStateEditId, EditorStateIdMappingEditId, EditorStateIdMappingUserId, EditorStateUserId,
    kAssetsCollectionName, kEditorStateCollectionName, kEditorStateIdMappingCollectionName, kLibrettoUserCollectionName,
    kTracksCollectionName,
    TrackDeleted,
    TrackId,
    TrackUserId
} from "../../utils/CollectionConstants";
import Typography from "@mui/material/Typography";
import {FetchContext} from "../../context/FetchContext";
import {
    ClipSize16And9RatioId, ClipSize9And16RatioId,
    DisplayToClipSizeId,
    loadTranscriptForTrack,
    RefreshTokenAndRetry
} from "../../utils/utils";
import ModernEditorInner from "./ModernEditorInner";
import NewEditorTopbar from "../NewEditor/NewEditorTopbar";
import {RendleyService} from "../../editor/services/RendleyService";
import {getCanvasElement} from "../../editor/utils/dom/getCanvasElement";
import RendleyBridge from "../../editor/RendleyBridge";
import {SUBTITLES_STYLES_PATH_CDN, TITLES_PATH_CDN} from "../../editor/config/config";
import {StockMediaService} from "../../editor/services/StockMediaService";
import {ClipTypeEnum, Engine} from "@rendley/sdk";
import {DialogContent} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import CreateSomethingCard from "./CreateSomethingCard";
import {RendleyStore} from "../../editor/store/RendleyStore";
import {deserializeInitialState} from "./EditorState";
import {ClientSideSuspense, RoomProvider, useSelf, useStorage} from "@liveblocks/react/suspense";
import RendleyLibrettoBridge from "./RendleyLibrettoBridge";
import SignInUserIntercomComponent from "../../components/IntercomComponent";
import LoadingDialog from "./LoadingDialog";
import {useCreateSaveEditorState} from "./ModernLiveblocks";

const LiveblocksWrappedEditor = () => {
    const {editId} = useParams();
    const [data, setData] = useState(null);
    const [correctionsMap, setCorrectionsMap] = useState(null);

    const fetchContext = useContext(FetchContext);
    const authContext = useContext(AuthContext);

    const navigate = useNavigate();

    useEffect(() => {
        const GetEditState = async () => {
            try {
                const response = await fetchContext.authAxios.get(`/editor/${editId}`, {
                    headers: {
                        Authorization: `Bearer ${authContext.getToken()}`
                    }
                });
                return response.data;
            } catch (e) {
                if (e.response.status === 401) {
                    await authContext.logout();
                    navigate("/");
                } else {
                    return null;
                }
            }
        }

        if (!editId) return;

        GetEditState().then((response) => {
            setData(response.data);
            if (response.data) {
                setCorrectionsMap(new Map(Object.entries(response.data.corrections.data)));
            }
        }).catch((e) => {
            console.log(e);
        });

    }, [editId]);

    return (
        <>
            {editId === null || data === null ? <Typography>Loading...</Typography> :
                <RoomProvider id={editId} initialPresence={{cursor: null}} autoConnect={true}>
                    <ClientSideSuspense fallback="Loading…">
                        {() =>
                            <>
                                <ModernEditorWrapper editId={editId} corrections={correctionsMap}
                                                     state={data.state} initialState={data.initialState.data}/>
                            </>}
                    </ClientSideSuspense>
                </RoomProvider>}
        </>
    )
}

const ModernEditorWrapper = ({editId, corrections, state, initialState}) => {
    const currentUser = useSelf();
    const [projectId, setProjectId] = useState(null);
    const [assetId, setAssetId] = useState(null);
    const [editTitle, setEditTitle] = useState(null);
    const canvasRef = useRef(null);
    const aspectRatioRef = useRef(null);
    const [isInitialized, setIsInitialized] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    const authContext = useContext(AuthContext);
    const userId = authContext.getUserId();

    const saveEditorState = useCreateSaveEditorState();

    // Get some edit metadata.
    useEffect(() => {
        const q = query(collection(db, kEditorStateCollectionName),
            where(EditorStateEditId, "==", editId),
            where(EditorStateUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let newProjectId = null;
            let newAssetId = null;
            let newEditTitle = null;
            querySnapshot.forEach((doc) => {
                newProjectId = doc.data().projectId;
                newAssetId = doc.data().assetId;
                newEditTitle = doc.data().title;
            });
            // Update the state only if the values have changed.
            if (newProjectId !== projectId || newAssetId !== assetId || newEditTitle !== editTitle) {
                setProjectId(newProjectId);
                setAssetId(newAssetId);
                setEditTitle(newEditTitle);
            }
        });

        return () => unsubscribe();
    }, [editId, userId]);

    const initializeEditor = async () => {
        // Step 1: Initialize the engine if not already initialized
        if (!Engine.getInstance().isInitialized()) {
            try {
                await Engine.getInstance().init({
                    display: {
                        width: 1920,
                        height: 1080,
                        backgroundColor: "#000000",
                        view: getCanvasElement(),
                    },
                    license: {
                        licenseName: "Libretto",
                        licenseKey: "7324FD51F1255979246B0001",
                    },
                });
            } catch (e) {
                console.error("Error initializing engine: ", e);
                return;
            }
        }

        // Step 2: Initialize services
        try {
            await StockMediaService.getInstance().init({
                pexelsApiKey: "ZPSMxFlBYLs9JQmTeFmSkeXDFf9VjJqHSAJCk6y5RyDFGmXV9edrThpl",
                giphyApiKey: "cGA8JsUtlRseBgqdBYSCCsFxgLYlm3tu",
            });
            await RendleyBridge.init({
                filtersPath: "",
                effectsPath: "",
                transitionsPath: "",
                titlesPath: TITLES_PATH_CDN,
                subtitlesStylesPath: SUBTITLES_STYLES_PATH_CDN,
            });
        } catch (e) {
            console.error("Error initializing services: ", e);
            return;
        }

        // Step 3: Load the saved state or initial state
        if (state && state.hasOwnProperty("display")) {
            setIsLoading(true);
            const mediaPromises = state?.library.media.map(async (media) => {

                const {filename, permanentUrl, mimeType, type, id} = media;

                let customDataObject, transcriptUrl, entityId;

                if (type === ClipTypeEnum.VIDEO || type === ClipTypeEnum.AUDIO) {
                    customDataObject = Object.fromEntries(media.customData);
                    transcriptUrl = customDataObject.transcriptUrl;

                    if (transcriptUrl) {
                        const fetchObject = fetch(transcriptUrl).then((response) => response.json());
                        const [transcript] = await Promise.all([fetchObject]);

                        // Set transcript on the media object customData with the key "transcript"
                        media.customData.push(["transcript", transcript]);
                    }
                }
            });
            await Promise.all(mediaPromises);
            await Engine.deserialize(state);
            canvasRef.current = Engine.getInstance().getDisplay().renderer.app?.view;
            const clipSizeId = DisplayToClipSizeId(Engine.getInstance().getDisplay());
            aspectRatioRef.current.handleClipSizeChange(clipSizeId);
            setIsLoading(false);
        } else if (initialState && initialState.hasOwnProperty("trackId")) {
            setIsLoading(true);
            await deserializeInitialState({
                track: initialState,
            });
            saveEditorState();
            setIsLoading(false);
        }
    };

    // Run the initialization on mount or when dependencies change
    useEffect(() => {
        if (canvasRef.current && !Engine.getInstance().isInitialized()) {
            initializeEditor();
        }
    }, [canvasRef.current]);

    // When component is unmounted, clean up the engine
    useEffect(() => {
        return () => {
            if (Engine.getInstance().isInitialized()) {
                Engine.getInstance()?.destroy(true).then(r => {
                });
            }
        }
    }, []);

    return (
        <Box>
            <LoadingDialog isLoading={isLoading}/>
            <ModernEditor projectId={projectId} assetId={assetId} editId={editId} canvasRef={canvasRef}
                          aspectRatioRef={aspectRatioRef} currentUser={currentUser} editTitle={editTitle}
                          corrections={corrections}/>
        </Box>
    )
}

const ModernEditor = ({assetId, editId, projectId, canvasRef, aspectRatioRef, currentUser, editTitle, corrections}) => {
    const authContext = useContext(AuthContext);
    const fetchContext = useContext(FetchContext);

    const navigate = useNavigate();

    const userId = authContext.getUserId();
    const [tracks, setTracks] = useState(new Map());
    const [tracksLoaded, setTracksLoaded] = useState(false);

    const [userPlan, setUserPlan] = useState(null);

    useEffect(() => {
        authContext.getSubscriptionStatusFromStorage({userId: authContext.getUserId()}).then((statusAndUserPlan) => {
            setUserPlan(statusAndUserPlan.userPlan)
        })
    });

    // Used when an edit is started without an associated asset. This will prompt user to upload an asset with which to associate the edit.
    const [showCreateSomethingDialog, setShowCreateSomethingDialog] = useState(false);

    const [selectedTrackIndex, setSelectedTrackIndex] = useState(0);

    const display = useStorage((root) => root.display) || {};
    const library = useStorage((root) => root.library) || [];
    const timeline = useStorage((root) => root.timeline) || [];

    const [captionsEnabled, setCaptionsEnabled] = useState(false);
    const [videoPlayerVisible, setVideoPlayerVisible] = useState(true);
    const [textEditorVisible, setTextEditorVisible] = useState(true);

    const [removeSilencesLoading, setRemoveSilencesLoading] = useState(false);
    const [removeFillerWordsLoading, setRemoveFillerWordsLoading] = useState(false);

    const editorRef = useRef(null);
    const videoplayerRef = useRef(null);
    const compositionContainerRef = useRef(null);

    const orderOfTracks = [];

    useEffect(() => {
        if (projectId && !assetId) {
            setShowCreateSomethingDialog(true)
        }
        if (projectId && assetId) {
            setShowCreateSomethingDialog(false)
        }

    }, [projectId, assetId]);

    const getTrackForSelectedIndex = () => {
        return tracks.get(orderOfTracks[selectedTrackIndex]);
    }

    // Load the tracks.
    useEffect(() => {
        if (!userId) {
            return;
        }

        if (orderOfTracks === undefined || orderOfTracks === null) {
            return;
        }
        if (orderOfTracks.length === 0) {
            setTracksLoaded(true);
            return;
        }

        const filterList = orderOfTracks && orderOfTracks.length > 0 ? orderOfTracks : [];

        const q = query(collection(db, kTracksCollectionName),
            where(TrackId, "in", filterList),
            where(TrackDeleted, "==", false),
            where(TrackUserId, "==", userId));

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let newTracks = [];
            querySnapshot.forEach((doc) => {
                newTracks.push(doc.data());
            });
            // Set the tracks to the tracks state map keyed by trackId
            setTracks(new Map(newTracks.map(track => [track.trackId, track])));
            setTracksLoaded(true);
        });

        return () => unsubscribe();
    }, [orderOfTracks, userId]);

    const pageStyle = {
        display: 'flex',
        flexDirection: 'column',
        background: "#F3F4F5",
        paddingTop: "20px",
        paddingLeft: "32px",
        paddingRight: "32px",
        height: "100vh",
        width: "100vw",
        gap: "28px",
        overflow: 'hidden',
    }

    const handleNavigateBack = () => {
        if (assetId) {
            navigate(`/assets/${assetId}`);
            window.location.reload();
        } else {
            navigate(`/dashboard`);
            window.location.reload();
        }
    }

    return (
        <Box sx={pageStyle}>
            <SignInUserIntercomComponent user={authContext.getUser()}/>
            {currentUser && <RendleyLibrettoBridge/>}
            <LoadingDialog isLoading={removeSilencesLoading || removeFillerWordsLoading} text={'Applying'}/>
            <NewEditorTopbar selectedTrackIndex={selectedTrackIndex} editTitle={editTitle}
                             containerRef={compositionContainerRef}
                             editId={editId}
                             assetId={assetId}
                             editorRef={editorRef}
                             removeFillerWordsLoading={removeFillerWordsLoading}
                             setRemoveFillerWordsLoading={setRemoveFillerWordsLoading}
                                removeSilencesLoading={removeSilencesLoading}
                             setRemoveSilencesLoading={setRemoveSilencesLoading}
                             canvasRef={canvasRef} handleNavigateBack={handleNavigateBack}
                             track={getTrackForSelectedIndex()} noAudioStream={true}
                             videoPlayerVisible={videoPlayerVisible}
                             textEditorVisible={textEditorVisible}
                             setVideoPlayerVisible={setVideoPlayerVisible}
                             setTextEditorVisible={setTextEditorVisible}
                             aspectRatioRef={aspectRatioRef} userPlan={userPlan}/>
            <Dialog
                open={showCreateSomethingDialog}
                onClose={() => {
                }}
                aria-labelledby="form-dialog-title"
                maxWidth={"1000px"}
            >
                <DialogContent sx={{
                    borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    padding: "0px 0px 0px 0px",
                }}>
                    <CreateSomethingCard projectId={projectId} editId={editId}/>
                </DialogContent>
            </Dialog>
            <ModernEditorInner
                compositionContainerRef={compositionContainerRef}
                canvasRef={canvasRef}
                projectId={projectId}
                handleSelect={() => console.log("Selected")} editorRef={editorRef}
                captionsEnabled={captionsEnabled}
                videoPlayerVisible={videoPlayerVisible}
                textEditorVisible={textEditorVisible}
                assetId={assetId}
                corrections={corrections}
                currentUser={currentUser}/>
        </Box>
    )
}

export default LiveblocksWrappedEditor;

