import React, {useCallback, useEffect, useRef, useState} from "react";
import SquareIcon from "@mui/icons-material/Square";
import Box from "@mui/material/Box";

export const WEBCAM_SIZE = 225;

export const AspectRatios = {
    '16:9': {width: 16, height: 9},
    '4:3': {width: 4, height: 3},
    '1:1': {width: 1, height: 1},
    '9:16': {width: 9, height: 16},
};

export const StartWebcam = async ({deviceId, setIsWebcamEnabled, setWebcamStream, webcamStream}) => {
    if (deviceId === "disabled") {
        StopCapture({streamType: 'webcam', webcamStream, setWebcamStream});
        setIsWebcamEnabled(false);
    } else {
        try {
            const constraints = { video: deviceId ? { deviceId: { exact: deviceId } } : true };
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            setWebcamStream(stream);
            setIsWebcamEnabled(true);
        } catch (error) {
            console.error('Error starting webcam:', error);
        }
    }
};

export const StartMicrophone = async ({deviceId, setIsMicEnabled, setMicStream, micStream}) => {
    if (deviceId === "disabled") {
        StopCapture({streamType: 'mic', setMicStream, micStream});
        setIsMicEnabled(false);
    } else {
        try {
            const constraints = { audio: deviceId ? { deviceId: { exact: deviceId } } : true };
            const stream = await navigator.mediaDevices.getUserMedia(constraints);
            setMicStream(stream);
            setIsMicEnabled(true);
        } catch (error) {
            console.error('Error starting microphone:', error);
        }
    }
};

export const StartScreenCapture = async ({ setScreenStream }) => {
    try {
        const stream = await navigator.mediaDevices.getDisplayMedia({
            video: {
                width: { ideal: 1920, max: 1920 }, // Ideal width in pixels
                height: { ideal: 1080, max: 1080 }, // Ideal height in pixels
                frameRate: { ideal: 30, max: 60 } // Desired frame rate
            },
            audio: true // Include audio
        });
        setScreenStream(stream);
    } catch (error) {
        console.error('Error starting screen capture:', error);
    }
};

export const StopCapture = ({streamType, webcamStream, micStream, screenStream, setWebcamStream, setMicStream, setScreenStream}) => {
    if (streamType === 'webcam' && webcamStream) {
        webcamStream.getTracks().forEach(track => track.stop());
        setWebcamStream(null);
    } else if (streamType === 'mic' && micStream) {
        micStream.getTracks().forEach(track => track.stop());
        setMicStream(null);
    } else if (streamType === 'screen' && screenStream) {
        screenStream.getTracks().forEach(track => track.stop());
        setScreenStream(null);
    }
};

export const AdjustWebcamPosition = ({newDimensions, webcamPosition, setWebcamPosition}) => {
    const containerWidth = parseFloat(newDimensions.width);
    const containerHeight = parseFloat(newDimensions.height);
    let newX = webcamPosition.x;
    let newY = webcamPosition.y;
    if (newX + WEBCAM_SIZE > containerWidth) {
        newX = containerWidth - WEBCAM_SIZE;
    }
    if (newY + WEBCAM_SIZE > containerHeight) {
        newY = containerHeight - WEBCAM_SIZE;
    }
    if (newX < 0 || newY < 0) {
        newX = (containerWidth - WEBCAM_SIZE) / 2;
        newY = (containerHeight - WEBCAM_SIZE) / 2;
    }
    setWebcamPosition({x: newX, y: newY});
};

const ButtonCheckIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="24" viewBox="0 0 25 24" fill="none">
            <path
                d="M12.5 22C6.977 22 2.5 17.523 2.5 12C2.5 6.477 6.977 2 12.5 2C18.023 2 22.5 6.477 22.5 12C22.5 17.523 18.023 22 12.5 22ZM12.5 20C14.6217 20 16.6566 19.1571 18.1569 17.6569C19.6571 16.1566 20.5 14.1217 20.5 12C20.5 9.87827 19.6571 7.84344 18.1569 6.34315C16.6566 4.84285 14.6217 4 12.5 4C10.3783 4 8.34344 4.84285 6.84315 6.34315C5.34285 7.84344 4.5 9.87827 4.5 12C4.5 14.1217 5.34285 16.1566 6.84315 17.6569C8.34344 19.1571 10.3783 20 12.5 20ZM12.5 15C11.7044 15 10.9413 14.6839 10.3787 14.1213C9.81607 13.5587 9.5 12.7956 9.5 12C9.5 11.2044 9.81607 10.4413 10.3787 9.87868C10.9413 9.31607 11.7044 9 12.5 9C13.2956 9 14.0587 9.31607 14.6213 9.87868C15.1839 10.4413 15.5 11.2044 15.5 12C15.5 12.7956 15.1839 13.5587 14.6213 14.1213C14.0587 14.6839 13.2956 15 12.5 15Z"
                fill="white"/>
        </svg>
    )
}

export const ScreenRecordButton = React.memo(({
                                        recordingInProgress,
                                        startRecording,
                                        stopRecording,
                                    }) => {
    const [recordTime, setRecordTime] = useState(0);
    const timerRef = useRef(null);

    useEffect(() => {
        if (recordingInProgress) {
            timerRef.current = setInterval(() => {
                setRecordTime(prevTime => prevTime + 1);
            }, 1000);
        } else {
            clearInterval(timerRef.current);
            setRecordTime(0);
        }

        return () => clearInterval(timerRef.current);
    }, [recordingInProgress]);

    const buttonStyle = () => {
        return ({
            backgroundColor: recordingInProgress  ? "#000" : "#F23000",
            color: "white",
            width: {md: "112px", lg: "179px", xl: "179px"},
            height: {lg: "39px", xl: "45px"},
            borderRadius: "8px",
            // Padding 24Px on left and right, and 14px on top and bottom
            // padding: "24px 14px",
            padding: '10px 18px',
            display: 'flex', // Ensure flexbox is used
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'row',
            gap: "10px",
            cursor: "pointer",
        })
    }

    const ButtonIcon = () => {
        const iconStyle = recordingInProgress
            ? {
                color: '#F23000',
                animation: 'flashing 1s infinite'
            }
            : {
                color: '#F23000'
            };


        if (recordingInProgress) {
            return (
                <SquareIcon style={iconStyle}/>
            );
        }

        return (
            <ButtonCheckIcon/>
        );
    }

    const handleButtonClick = async () => {
        if (recordingInProgress) {
            await stopRecording();
        } else {
            await startRecording();
        }
    }

    const formatTime = ({recordTime}) => {
        const hours = Math.floor(recordTime / 3600).toString().padStart(2, '0');
        const minutes = Math.floor((recordTime % 3600) / 60).toString().padStart(2, '0');
        const seconds = (recordTime % 60).toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
    };

    const ButtonText = () => {
        if (recordingInProgress) {
            return (
                <Box style={textStyle}>
                    {formatTime({recordTime})}
                </Box>
            );
        }

        return (
            <Box style={textStyle}>
                Record
            </Box>
        );
    }

    const textStyle = {
        fontFamily: "Inter",
        fontSize: {md: "5px", lg: "5px", xl: "14px"},
        fontStyle: "normal",
        fontWeight: {md: "350", lg: "400", xl: "500"},
        lineHeight: {md: "13px", lg: "15px", xl: "20px"},
        letterSpacing: {md: "0.1px", lg: "0.1px", xl: "0.14px"},
        color: "white",
        // disable highlight on double click
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
    }

    const textContainerStyle = {
        cursor: "pointer",
    }

    return (
        <>
            <style>
                {`
                    @keyframes flashing {
                        0% { opacity: 1; }
                        50% { opacity: 0; }
                        100% { opacity: 1; }
                    }
                `}
            </style>
            {<Box onClick={handleButtonClick} sx={buttonStyle()}>
                {<ButtonIcon/>}
                <Box style={textContainerStyle}>{ButtonText()}</Box>
            </Box>}
        </>);
});