import {useNavigate} from "react-router-dom";

import React, {useEffect, useRef, useState} from "react";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {Button, DialogContent, Typography} from "@mui/material";
import {NewCustomControlBar} from "../NewStudio/NewCustomControlBar";
import {NewGuestRecordingStoppedCard, NewRecordingStoppedCard} from "../Studio/RecordingStoppedCard";
import {LiveKitRoom} from "@livekit/components-react";
import {
    NewGuestStudioVideoConference,
    NewGuestStudioVideoConferenceVideoRecording,
    NewStudioVideoConference
} from "../../components/LibrettoConference";
import {
    AdaptRecordingMode,
    GridVideoLayout,
    LibrettoRecordingMode, LibrettoStudioNameStyle, LibrettoStudioTheme,
    StandardVideoResolution,
    StudioNameStyleString
} from "../../utils/utils";
import {collection, onSnapshot, query, where} from "firebase/firestore";
import {db} from "../../Firebase";
import {kRoomStateCollectionName, RoomName} from "../../utils/CollectionConstants";
import {RecordingStateDisplay} from "../NewStudio/NewRecordButton";
import {CustomLeaveButton} from "./CustomLeaveButton";
import Dialog from "@mui/material/Dialog";
import {UnauthenticatedUserIntercomComponent} from "../../components/IntercomComponent";

const serverUrl = 'wss://librettobeta-htow58t4.livekit.cloud'

const NewGuestStudio = ({guestToken, userChoices, userFriendlyStudioName, roomName, hostIdentity}) => {
    const navigate = useNavigate();

    const theme = useTheme();

    const isTooSmall = useMediaQuery(theme.breakpoints.down('smd'));

    const announcementBarStyle = {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '12px',
        backgroundColor: '#2B6BFD', // You can change this to match your theme
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999, // Ensure it's above other elements
        overflow: 'hidden',
        transition: 'height 0.3s ease',
        '&:hover': {
            height: '20px', // Expands on hover for better readability
        }
    };

    const announcementTextStyle = {
        color: 'white',
        fontSize: '13px',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    };

    const pageStyle = (theme, showAnnouncement) => {

        let backgroundColor = theme === LibrettoStudioTheme.Light ? "#f3f4f5" : "#1A1A1A";
        if (theme === LibrettoStudioTheme.Libretto) {
            backgroundColor = "#2B6BBD";
        }

        return ({
            paddingTop: "12px",
            height: "100vh",
            width: "100vw",
            background: `radial-gradient(circle at top, ${backgroundColor} 0%, ${backgroundColor} 50%, ${backgroundColor} 100%), linear-gradient(180deg, ${backgroundColor} 0%, ${backgroundColor} 50%, ${backgroundColor} 100%)`,
        })
    }

    const [backgroundImage, setBackgroundImage] = useState({isBlur: false, imageUrl: null});

    const isXl = useMediaQuery(theme.breakpoints.up('xl'));
    const isLg = useMediaQuery(theme.breakpoints.up('lg'));
    const isMd = useMediaQuery(theme.breakpoints.up('md'));

    const barHeight = isXl ? "100px" : (isLg ? "75px" : "66px");

    const [videoAreaFullScreen, setVideoAreaFullScreen] = useState(false);

    const videoHeight = videoAreaFullScreen ? "100vh" : isXl ? "calc(100vh - 316px)" : (isLg ? "calc(100vh - 266px)" : "calc(100vh - 248px)");

    const videoWidth = videoAreaFullScreen ? "100vw" : isXl ? "calc((100vh - 316px) * 16 / 9)" : (isLg ? "calc((100vh - 266px) * 16 / 9)" : "calc((100vh - 248px) * 16 / 9)");

    const videoDisplayStyle = (videoAreaFullScreen) => {
        return ({
            // Set height to be everything that remains in the viewport after subtracting 316px for the control bar and soundboard together.
            height: videoHeight,
            // Set the width based on the height to maintain the aspect ratio of 16:9.
            width: videoWidth,
            maxWidth: videoAreaFullScreen ? "100vw" : "90vw",
        })
    }


    const controlBarMarginBottom = isXl ? "38px" : (isLg ? "32px" : "22px");

    const [showRecordingEndedCard, setShowRecordingEndedCard] = useState(true);

    document.addEventListener('keydown', (event) => {
        if (event.ctrlKey && event.key === 'f') {
            event.preventDefault(); // Prevent the default F11 behavior
            setVideoAreaFullScreen(!videoAreaFullScreen);
        } else if (event.key === 'Escape' && videoAreaFullScreen) {
            setVideoAreaFullScreen(false);
        }
    });

    const controlBarStyle = (isTooSmall) => {
        return {
            height: barHeight,
            width: "auto",
            paddingLeft: "22px",
            paddingRight: "22px",
            marginBottom: controlBarMarginBottom,
            gap: "12px",
            alignItems: "center",
            display: isTooSmall ? "none" : "inline-flex",
            backgroundColor: "#ffffff",
            borderRadius: "12px",
            boxShadow: "0px 20px 75px 0px rgba(0, 0, 0, 0.05)",
        }
    }

    const [cloudEgressStarted, setCloudEgressStarted] = useState(false);   // This means cloud egress has been started by the backend.
    const [recordingInProgress, setRecordingInProgress] = useState(false); // This is the client UI state to show flashing red and timer.
    const [countdown, setCountdown] = useState(null);
    const [recordingStarting, setRecordingStarting] = useState(false);
    const [localRecordingInProgress, setLocalRecordingInProgress] = useState(false)
    const [localRecordingDone, setLocalRecordingDone] = useState(false);
    const [selectedVideoResolution, setSelectedVideoResolution] = useState(StandardVideoResolution);
    const [noiseCancellationEnabled, setNoiseCancellationEnabled] = useState(false);
    const [localRecordingEnabled, setLocalRecordingEnabled] = useState(false);
    const [videoMirrored, setVideoMirrored] = useState(false);
    const [recordingMode, setRecordingMode] = useState(LibrettoRecordingMode.Audio);
    const [studioTheme, setStudioTheme] = useState(LibrettoStudioTheme.Light);
    const [studioNameStyle, setStudioNameStyle] = useState(LibrettoStudioNameStyle.Clean);

    const [uploadProgress, setUploadProgress] = useState(0);

    const assetIdRef = useRef(null);

    const [localRecordingStopped, setLocalRecordingStopped] = useState(false);

    // This is called so that the recording can be restarted after it has been stopped. (And recording stopped card
    // can be shown again.)
    const handleRecordingRestart = () => {
        setLocalRecordingStopped(false);
        setLocalRecordingDone(false);
        setShowRecordingEndedCard(true);
    }

    const readableRecordingMode = (mode) => {
        return mode === LibrettoRecordingMode.Audio ? "audio-only recording" : "video recording";
    }

    const startHQRecording = () => {
        if (highQualityRecordingRef.current && !highQualityRecordingRef.current.isHighQualityRecordingActive()) {
            highQualityRecordingRef.current.startRecording();
        }
    }

    const reliablyStartRecording = () => {
        startHQRecording();
        // Call start recording a couple of times to make sure it starts.
        setTimeout(() => {
            startHQRecording();
        }, 300);
        setTimeout(() => {
            startHQRecording();
        }, 600);
    }

    const stopHQRecording = () => {
        if (highQualityRecordingRef.current && highQualityRecordingRef.current.isHighQualityRecordingActive()) {
            highQualityRecordingRef.current.stopRecording();
        }
    }

    const reliablyStopRecording = () => {
        stopHQRecording();
        setTimeout(() => {
            stopHQRecording();
        }, 300);
        setTimeout(() => {
            stopHQRecording();
        }, 600);
    }

    const [showAnnouncement, setShowAnnouncement] = useState(true); // State for the announcement bar [true/false
    const [announcement, setAnnouncement] = useState("Welcome to " + userFriendlyStudioName + ". The recording mode is " + readableRecordingMode(recordingMode) + "."); // State for the announcement message

    useEffect(() => {
        console.log("Recording mode changed to " + recordingMode + ".");
        setAnnouncement("Welcome to " + userFriendlyStudioName + ". The recording mode is " + readableRecordingMode(recordingMode) + ". Press Ctrl+F to toggle full screen for the video display.");
    }, [recordingMode]);

    const [selectedBrandLogo, setSelectedBrandLogo] = useState({assetId: null, url: null});

    const [triggerReload, setTriggerReload] = useState(false);

    const [room, setRoom] = useState(null);

    const [backgroundImageIndex, setBackgroundImageIndex] = useState(null);

    const highQualityRecordingRef = useRef(null);

    useEffect(() => {
        if (roomName !== '') {

            const q = query(collection(db, kRoomStateCollectionName), where(RoomName, "==", roomName));
            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const newRoomState = [];
                querySnapshot.forEach((doc) => {
                    newRoomState.push(doc.data());
                });
                if (newRoomState.length > 0) {
                    setNoiseCancellationEnabled(newRoomState[0].backgroundNoiseCancellationEnabled);
                    setLocalRecordingEnabled(newRoomState[0].hasOwnProperty("localRecordingEnabled") ? newRoomState[0].localRecordingEnabled : true);
                    setVideoMirrored(newRoomState[0].hasOwnProperty("videoMirrored") ? newRoomState[0].videoMirrored : false);
                    setSelectedVideoResolution(newRoomState[0].videoRecordingQuality);
                    setRecordingMode(AdaptRecordingMode(newRoomState[0].recordingType));
                    setStudioTheme(newRoomState[0].studioTheme  ? newRoomState[0].studioTheme : LibrettoStudioTheme.Light);
                    setStudioNameStyle(newRoomState[0].studioNameStyle ? newRoomState[0].studioNameStyle : LibrettoStudioNameStyle.Clean);
                    setSelectedBrandLogo(newRoomState[0].studioBrandLogo && newRoomState[0].studioBrandLogo != "" ? newRoomState[0].studioBrandLogo : {assetId: null, url: null});
                    // RoomNotRecording
                    if (newRoomState[0].roomState === 0) {
                        setRecordingInProgress(false);
                        setCloudEgressStarted(false);
                        setCountdown(null);
                        setRecordingStarting(false);
                        reliablyStopRecording();
                    }
                    // RoomRecordingStarting
                    if (newRoomState[0].roomState === 1 && countdown === null) {
                        setRecordingStarting(true);
                        assetIdRef.current = newRoomState[0].currentAssetId;
                        setCountdown(5);
                        setShowAnnouncement(false);
                        handleRecordingRestart();
                        reliablyStartRecording();
                    }
                    // RoomRecordingInProgress
                    if (newRoomState[0].roomState === 2 && !cloudEgressStarted) {
                        setCloudEgressStarted(true);
                        reliablyStartRecording();
                    }
                }
            });

            return () => unsubscribe();
        } else {

        }
    }, [roomName]);

    // The client UI state.
    useEffect(() => {
        if (cloudEgressStarted && countdown === null) {
            setRecordingInProgress(true);
        }
    }, [cloudEgressStarted, countdown]);

    useEffect(() => {
        setLocalRecordingInProgress(recordingStarting || recordingInProgress);
    }, [recordingStarting, recordingInProgress]);

    // Countdown before recording starts.
    useEffect(() => {
        let intervalId;
        if (countdown > 0) {
            intervalId = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setCountdown(null);
        }

        // Clear interval on unmount or countdown change
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [countdown]);

    const titleBoxStyle = {
        display: isMd ? 'flex' : 'none',
    }

    const titleStyle = (theme) => {
        return ({
            fontFamily: "Inter",
            color: theme === LibrettoStudioTheme.Light ? "#1A1A1A" : "#ffffff",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "24px",
            letterSpacing: "0.14px",
            // Hide the title if the screen is below 1200px.
        })
    }

    const countdownBoxStyle = {
        position: "absolute",
        height: "100vh",
        width: "100vw",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: "rgba(26, 26, 26, 0.15)",
        zIndex: 1000,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
    }

    const countdownTextStyle = {
        color: "#2B6BFD",
        fontSize: "120px",
        fontStyle: "normal",
        fontWeight: "800",
        lineHeight: "normal",
        opacity: 1,
        zIndex: 999999,
        fontFamily: "Inter, sans-serif",  // Added fallback font
    }

    const onLeave = () => {
        navigate("/dashboard");
    }

    const soundBoardStyle = (videoAreaFullScreen) => {
        return ({
            height: barHeight,
            marginTop: controlBarMarginBottom,
            display: videoAreaFullScreen ? 'none' : 'inline-flex',
            paddingLeft: "22px",
            paddingRight: "22px",
            paddingTop: "12px",
            paddingBottom: "12px",
            width: "auto",
            borderRadius: "12px",
            boxShadow: "0px 20px 100px 0px rgba(0, 0, 0, 0.05)",
            backgroundColor: "#ffffff",
            flexDirection: "row",
            alignItems: "center",
            gap: "12px",
        })
    }

    const topAreaStyle = (videoAreaFullScreen) =>  {
        return ({
            display: videoAreaFullScreen ? 'none' : 'flex',
        })
    }

    return (
        <>
            {showAnnouncement && !isTooSmall && <Box sx={announcementBarStyle}>
                <Typography sx={announcementTextStyle}>{announcement}</Typography>
            </Box>}
            <UnauthenticatedUserIntercomComponent/>
            <Dialog
                open={isTooSmall}
                onClose={() => {}}
                aria-labelledby="form-dialog-title"
            >
                <DialogContent sx={{borderRadius: '30px',
                    display: 'flex',        // Enable flexbox
                    justifyContent: 'center', // Center horizontally
                    alignItems: 'center',     // Center vertically
                    minWidth: { xs: '300px', md: '400px' },
                }}>
                    <Typography><b>Please switch to a larger screen.</b> For now, you need a larger screen here.</Typography>
                </DialogContent>
            </Dialog>
        <Grid container direction="column" style={pageStyle(studioTheme, showAnnouncement)} justifyContent="center" alignItems="center">
            <Grid container justifyContent="space-between" alignItems="flex-start" position="relative" sx={topAreaStyle(videoAreaFullScreen)}>
                <Grid item marginLeft="32px" paddingTop={0} width="210px"
                      style={{display: "flex", justifyContent: "flex-start"}}>
                    <Box display={'flex'} gap={"8px"} paddingBottom={2} alignItems={"center"} justifyContent={"center"}>
                        <Box style={titleBoxStyle}>
                            <Typography style={titleStyle(studioTheme)}>{userFriendlyStudioName}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid container item style={controlBarStyle(isTooSmall)} alignItems="center">
                    {room ? <NewCustomControlBar room={room} setBackgroundImage={setBackgroundImage}
                                                 roomName={roomName} controls={{chat: false}}
                                                 recordingInProgress={recordingInProgress}
                                                 backgroundImageIndex={backgroundImageIndex}
                                                 setBackgroundImageIndex={setBackgroundImageIndex}
                                                 isRecordButtonDisabled={false}
                                                 localRecordingInProgress={localRecordingInProgress}/> :
                        <Typography>Loading control bar..</Typography>}
                </Grid>
                <Grid item marginRight="32px" width="210px" style={{display: isTooSmall ? "none" : "flex", justifyContent: "flex-end"}}>
                    <Box
                        component={'img'}
                        src={'/logo-new.svg'}
                        height={0.65}
                        width={0.65}
                        onClick={() => navigate("/dashboard")}
                        style={{cursor: "pointer"}}
                    />
                </Grid>
            </Grid>
            {localRecordingStopped ? <NewGuestRecordingStoppedCard uploadProgress={uploadProgress} showRecordingEndedCard={showRecordingEndedCard} setShowRecordingEndedCard={setShowRecordingEndedCard} localRecordingDone={localRecordingDone}/> : null}
            <Grid item justifyContent="center" alignItems="center" style={videoDisplayStyle(videoAreaFullScreen)}>
                <LiveKitRoom key={triggerReload} serverUrl={serverUrl} token={guestToken}
                             data-lk-theme="default"
                             video={userChoices.videoEnabled} audio={userChoices.audioEnabled}
                             style={{height: '100%', width: '100%', borderRadius: '8px'}}
                             connect={true} onDisconnected={onLeave}>
                    {recordingMode === LibrettoRecordingMode.Audio ? <NewGuestStudioVideoConference
                        assetIdRef={assetIdRef}
                        localRecordingEnabled={localRecordingEnabled}
                        backgroundImage={backgroundImage}
                        recordingInProgress={recordingInProgress}
                        setUploadProgress={setUploadProgress}
                        highQualityRecordingRef={highQualityRecordingRef}
                        videoMirrored={videoMirrored}
                        startRecording={null}
                        roomName={roomName}
                        studioNameStyle={StudioNameStyleString(studioNameStyle)}
                        localRecordingDone={localRecordingDone}
                        stopRecording={null}
                        hostIdentity={hostIdentity}
                        brandLogoUrl={selectedBrandLogo.url}
                        enableKrispNoiseCancellation={noiseCancellationEnabled}
                        localRecordingInProgress={localRecordingInProgress}
                        livekitToken={guestToken}
                        setLocalRecordingDone={setLocalRecordingDone}
                        setLocalRecordingStopped={setLocalRecordingStopped}
                        videoResolution={selectedVideoResolution}
                        setRoom={setRoom}
                    /> : <NewGuestStudioVideoConferenceVideoRecording
                        assetIdRef={assetIdRef}
                        setUploadProgress={setUploadProgress}
                        localRecordingEnabled={localRecordingEnabled}
                        backgroundImage={backgroundImage}
                        recordingInProgress={recordingInProgress}
                        highQualityRecordingRef={highQualityRecordingRef}
                        startRecording={null}
                        videoMirrored={videoMirrored}
                        roomName={roomName}
                        studioNameStyle={StudioNameStyleString(studioNameStyle)}
                        localRecordingDone={localRecordingDone}
                        stopRecording={null}
                        hostIdentity={hostIdentity}
                        brandLogoUrl={selectedBrandLogo.url}
                        enableKrispNoiseCancellation={noiseCancellationEnabled}
                        localRecordingInProgress={localRecordingInProgress}
                        livekitToken={guestToken}
                        setLocalRecordingDone={setLocalRecordingDone}
                        setLocalRecordingStopped={setLocalRecordingStopped}
                        videoResolution={selectedVideoResolution}
                        setRoom={setRoom} />}
                </LiveKitRoom>
            </Grid>
            <Grid container item justifyContent="center" alignItems="center" display={"inline-flex"}>
                <Grid container item justifyContent="center" alignItems="center" display="flex">
                    <Box style={soundBoardStyle(videoAreaFullScreen)}>
                        <RecordingStateDisplay recordingInProgress={recordingInProgress} localRecordingInProgress={localRecordingInProgress}/>
                        {room && <CustomLeaveButton room={room}/>}
                    </Box>
                </Grid>
            </Grid>
            <Grid item alignSelf="center">
                {countdown !== null && countdown > 0 && (<Box
                    style={countdownBoxStyle}
                >
                    <Box style={countdownTextStyle}
                    >
                        {countdown}
                    </Box>
                </Box>)}
            </Grid>
        </Grid>
        </>
    );
}

export default NewGuestStudio;
