import { ClipTypeEnum, Engine } from "@rendley/sdk";
import { action, makeAutoObservable, observable } from "mobx";
import { TIMELINE_MIN_DURATION } from "../config/config";

export enum MediaDataStatusEnum {
  LOADING = "loading",
  READY = "ready",
}

export interface ClipFilter {
  id: string;
  sourceId: string;
}

export type ClipEffect = ClipFilter;

export interface Clip {
    id: string;
    trackId: string;
    type: ClipTypeEnum;
    startTime: number;
    leftTrim: number;
    rightTrim: number;
    duration: number;
    trimmedDuration: number;
    mediaDataId?: string;
    text?: string;
    hasPredecessor?: boolean;
    hasSuccessor?: boolean;
}

export interface Transition {
  id: string;
  startClipId: string;
  endClipId: string;
  inDuration: number;
  outDuration: number;
}

export interface Layer {
  id: string;
  clipsIds: string[];
  transitionIds: string[];
}

export interface Display {
  width: number;
  height: number;
  backgroundColor: string;
}

export interface MediaData {
  id: string;
  thumbnail?: string;
  type?: string;
  filename?: string;
  status: MediaDataStatusEnum;
  duration?: number;
}

export interface Render {
  blobUrl?: string;
  error?: string;
}

export interface SubtitlesStyles {
    selectedEnabled: boolean;
    selectedMode: string | null;
    selectedAnimation: string | null;
    selectedStyle: string | null;
    subtitlesClipId: string | null;
}

class RendleyStoreInstance {
    isPlaying: boolean = false;
    editId: string = "";
    currentTime: number = 0;
    duration: number = TIMELINE_MIN_DURATION;
    display: Display = {
        width: 1920,
        height: 1080,
        backgroundColor: "#000000",
    };
    media: Record<string, MediaData> = {};
    clips: Record<string, Clip> = {};
    layers: Record<string, Layer> = {};
    transitions: Record<string, Transition> = {};
    layersOrder: string[] = [];
    clipFilters: Record<string, ClipFilter[]> = {};
    clipEffects: Record<string, ClipEffect[]> = {};
    styles: Record<string, any> = {};
    updateTimestamp: number = 0;
    render: Render = {
        blobUrl: undefined,
        error: undefined,
    };
    subtitlesStyles: SubtitlesStyles = {
        selectedEnabled: false,
        selectedMode: "full",
        selectedAnimation: null,
        selectedStyle: null,
        subtitlesClipId: null,
    };

  constructor() {
    makeAutoObservable(this, {
      setDuration: action,
      updateTimelineDuration: action,
      resetRender: action,
      updateRender: action,
      setDisplayResolution: action,
      setDisplayBackgroundColor: action,
      setIsPlaying: action,
      setCurrentTime: action,
      addLayer: action,
      deleteLayer: action,
      setClip: action,
      setClips: action,
      addFilter: action,
      addEffect: action,
      deleteFilter: action,
      deleteEffect: action,
      updateClip: action,
      updateLayer: action,
      updateStyles: action,
      setStyles: action,
      addClip: action,
      addMedia: action,
      deleteMedia: action,
      setLayersOrder: action,
      addTransition: action,
      setTransitions: action,
      deleteTransition: action,
      setUpdateTimestamp: action,
      getLayerFromClipId: observable,
      getLayerFromTransitionId: observable,
      getFilenameByClipId: observable,
      getTextByClipId: observable,
      getThumbnailByClipId: observable,
    });
  }

  getFilenameByClipId(clipId: string) {
    const mediaDataId = RendleyStore.clips[clipId]?.mediaDataId;

    if (mediaDataId == null) {
      return undefined;
    }

    return RendleyStore.media[mediaDataId]?.filename;
  }

  setFilenameByClipId(clipId: string, filename: string) {
    const mediaDataId = RendleyStore.clips[clipId]?.mediaDataId;

    if (mediaDataId == null) {
      return;
    }

    RendleyStore.media[mediaDataId].filename = filename;
  }

    setTrackIdByClipId(clipId: string, trackId: string) {
        RendleyStore.clips[clipId].trackId = trackId;
    }

    getThumbnailByClipId(clipId: string) {
        const mediaDataId = RendleyStore.clips[clipId]?.mediaDataId;

    if (mediaDataId == null) {
      return undefined;
    }

    return RendleyStore.media[mediaDataId]?.thumbnail;
  }

  getTextByClipId(clipId: string) {
    return RendleyStore.clips[clipId]?.text;
  }

  setDuration(duration: number) {
    this.duration = duration;
  }

  updateTimelineDuration() {
    // 10 second offset
    const duration = Math.max(TIMELINE_MIN_DURATION, Engine.getInstance().getTimeline().getFitDuration() + 10);
    this.setDuration(duration);
  }

  resetRender() {
    this.render = {
      error: undefined,
      blobUrl: undefined,
    };
  }

  updateRender(payload: Partial<RendleyStoreInstance["render"]>) {
    this.render = {
      ...this.render,
      ...payload,
    };
  }

  getLayerFromClipId(clipId: string) {
    const layerId = this.layersOrder.find((layerId) => this.layers[layerId].clipsIds.includes(clipId));

        if (layerId == null) {
            return null;
        }

        return this.layers[layerId];
    }

    getLayerIndexByLayerId(layerId: string) {
        const layerIndex = this.layersOrder.findIndex((id) => id === layerId)

        if (layerIndex == null) {
            return null;
        }

        return layerIndex;
    }

    getLayerFromTransitionId(transitionId: string) {
        const layerId = this.layersOrder.find((layerId) => this.layers[layerId].transitionIds.includes(transitionId));

        if (layerId == null) {
            return null;
        }

        return this.layers[layerId];
    }

    setDisplayResolution(width: number, height: number) {
        this.display.width = width;
        this.display.height = height;
    }

    setDisplayBackgroundColor(backgroundColor: string) {
        this.display.backgroundColor = backgroundColor;
    }

    setIsPlaying(isPlaying: boolean) {
        this.isPlaying = isPlaying;
    }

    setCurrentTime(currentTime: number) {
        this.currentTime = currentTime;
    }

    addLayer(layer: Layer) {
        this.layers[layer.id] = layer;
    }

    deleteLayer(layerId: string) {
        const layer = this.layers[layerId];

        if (layer == null) {
            return;
        }

        this.layersOrder = this.layersOrder.filter((l) => l !== layerId);

        layer.clipsIds.forEach((clipId) => {
            this.deleteClip(clipId, layerId);
        });

        layer.transitionIds.forEach((transitionId) => {
            this.deleteTransition(transitionId, layerId);
        });

        delete this.layers[layerId];
    }

    setClip(clip: Clip) {
        this.clips[clip.id] = clip;
    }

    setClips(payload: RendleyStoreInstance["clips"]) {
        this.clips = payload;
    }

    addFilter(clipId: string, filter: ClipFilter) {
        this.clipFilters[clipId] = [...(this.clipFilters[clipId] ?? []), filter];
    }

    addEffect(clipId: string, effect: ClipEffect) {
        this.clipEffects[clipId] = [...(this.clipEffects[clipId] ?? []), effect];
    }

    deleteFilter(clipId: string, filterId: string) {
        this.clipFilters[clipId] = this.clipFilters[clipId].filter((f) => f.id !== filterId);
    }

    deleteEffect(clipId: string, effectId: string) {
        this.clipEffects[clipId] = this.clipEffects[clipId].filter((e) => e.id !== effectId);
    }

    updateClip(clipId: string, payload: Record<string, any>) {
        this.clips[clipId] = {
            ...this.clips[clipId],
            ...payload,
        };
    }

    updateLayer(layerId: string, payload: Record<string, any>) {
        this.layers[layerId] = {
            ...this.layers[layerId],
            ...payload,
        };
    }

    updateStyles(clipId: string, styles: Record<string, any>) {
        this.styles[clipId] = {
            ...(this.styles[clipId] ?? {}),
            ...styles,
        };
    }

    setStyles(styles: Record<string, any>) {
        this.styles = styles;
    }

    addClip(clip: Clip) {
        this.clips[clip.id] = clip;
    }

    deleteClip(clipId: string, layerId: string) {
        this.layers[layerId].clipsIds = this.layers[layerId].clipsIds.filter((id) => id !== clipId);
        delete this.clips[clipId];
    }

    setMedia(payload: RendleyStoreInstance["media"]) {
        this.media = payload;
    }

    setLayers(payload: RendleyStoreInstance["layers"]) {
        this.layers = payload;
    }

    addMedia(mediaData: MediaData) {
        this.media[mediaData.id] = mediaData;
    }

    deleteMedia(mediaId: string) {
        delete this.media[mediaId];
    }

    setLayersOrder(layersIds: string[]) {
        this.layersOrder = layersIds;
    }

    addTransition(transition: Transition) {
        this.transitions[transition.id] = transition;
    }

    setTransitions(payload: RendleyStoreInstance["transitions"]) {
        this.transitions = payload;
    }

    deleteTransition(transitionId: string, layerId: string) {
        this.layers[layerId] = {
            ...this.layers[layerId],
            transitionIds: this.layers[layerId].transitionIds.filter((t) => t !== transitionId),
        };

        delete this.transitions[transitionId];
    }

    setUpdateTimestamp(timestamp: number) {
        this.updateTimestamp = timestamp;
    }

    setSubtitlesMode(value: string) {
        this.subtitlesStyles.selectedMode = value;
    }
    setSubtitlesAnimation(value: string | null) {
        this.subtitlesStyles.selectedAnimation = value;
    }
    setSubtitlesStyle(value: string | null) {
        this.subtitlesStyles.selectedStyle = value;
    }

    setSubtitlesEnabled(value: boolean) {
        this.subtitlesStyles.selectedEnabled = value;
    }

    setSubtitlesClipId(clipId: string) {
      this.subtitlesStyles.subtitlesClipId = clipId;
    }

    setEditId(editId: string) {
        this.editId = editId;
    }

    getEditId() {
        return this.editId;
    }
}

const RendleyStore = new RendleyStoreInstance();

export {RendleyStore};
