import {forwardRef, useContext, useEffect, useImperativeHandle, useRef, useState} from "react";
import {RendleyStore} from "../../editor/store/RendleyStore";
import {reaction} from "mobx";
import {ApplicationStore} from "../../editor/store/ApplicationStore";
import {LexicalComposer} from "@lexical/react/LexicalComposer";
import Container from "../../components/Container";
import {RichTextPlugin} from "@lexical/react/LexicalRichTextPlugin";
import {ContentEditable} from "@lexical/react/LexicalContentEditable";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import {OnChangePlugin} from "@lexical/react/LexicalOnChangePlugin";
import {NodeEventPlugin} from "@lexical/react/LexicalNodeEventPlugin";
import {HistoryPlugin} from "@lexical/react/LexicalHistoryPlugin";
import {MarkdownShortcutPlugin} from "@lexical/react/LexicalMarkdownShortcutPlugin";
import {HEADING} from "@lexical/markdown";
import {CorrectTextBox, EditSelectedCard, SilenceInfoCard} from "../Editor/EditSelectedCard";
import {
    $createParagraphNode,
    $createTextNode,
    $getNodeByKey,
    $getRoot,
    $getSelection,
    $nodesOfType,
    $setSelection
} from "lexical";
import {ClipTypeEnum, Engine, EventsEnum} from "@rendley/sdk";
import {debounce} from "lodash-es";
import {HeadingNode} from "@lexical/rich-text";
import {$createModernSoundNode, $isModernSoundNode, ModernSoundNode} from "./ModernSoundNode";
import {OptimizeFillerWordRemovalTrim, ParseTranscriptWords} from "../Editor/Transcript";
import {
    BuildOnRoot,
    calculateSoundNodeTimestamp, ClipsToTimelineItems,
    CreateMultiTrackEditorState,
    dotsForDuration,
    zeroIfNull
} from "./MultitrackTranscript";
import {useLexicalComposerContext} from "@lexical/react/LexicalComposerContext";
import {useCreateSaveTranscriptCorrection} from "./ModernLiveblocks";
import {useStorage} from "@liveblocks/react";
import {RendleyService} from "../../editor/services/RendleyService";

const editorInputStyle = {
    textAlign: 'justify',
    overflowY: 'auto', // Ensures content is scrollable only vertically
    height: 'calc(100vh - 200px)', // Optional for setting a fixed height
    maxWidth: '820px',
    paddingLeft: '50px',
    paddingRight: '50px',
    paddingTop: '20px',
    scrollbarWidth: 'none',
    outline: 'none',
};

function RefPlugin({editorRef}) {
    const [editor] = useLexicalComposerContext()
    editorRef.current = editor
    return null
}

const ModernTextEditor = ({editorRef, corrections}) => {

    const [currentTime, setCurrentTime] = useState(RendleyStore.currentTime);
    const [selectedClipId, setSelectedClipId] = useState(ApplicationStore.selectedClipId);

    const timelineStateLayers = ClipsToTimelineItems(RendleyStore.clips);

    const [localCorrectionsState, setLocalCorrectionsState] = useState(corrections);

    useEffect(() => {
        const dispose = reaction(
            () => ({
                selectedClipId: ApplicationStore.selectedClipId,
                currentTime: RendleyStore.currentTime,
            }),
            (state) => {
                setSelectedClipId(state.selectedClipId);
                setCurrentTime(state.currentTime);
            }
        );
        return () => {
            dispose();
        };
    }, []);

    const editorState = CreateMultiTrackEditorState({
        timelineStateLayers: timelineStateLayers, corrections: localCorrectionsState
    });

    useEffect(() => {
        const handleClipUpdated = (payload) => {
            if (editorRef.current) {
                const timelineItems = ClipsToTimelineItems(RendleyStore.clips);
                editorRef.current.recreateEditorState(timelineItems, localCorrectionsState);
            }
        };

        window.addEventListener("LIBRETTO_CLIP_UPDATED", handleClipUpdated);
        window.addEventListener("LIBRETTO_CLIP_ADDED", handleClipUpdated);
        window.addEventListener("LIBRETTO_CLIP_REMOVED", handleClipUpdated);

        return () => {
            window.removeEventListener("LIBRETTO_CLIP_UPDATED", handleClipUpdated);
            window.removeEventListener("LIBRETTO_CLIP_ADDED", handleClipUpdated);
            window.removeEventListener("LIBRETTO_CLIP_REMOVED", handleClipUpdated);
        };

    }, [localCorrectionsState]);


    // Move cursor on text editor while playing.
    useEffect(() => {
        if (editorRef.current) {
            editorRef.current.updateCurrentTime(currentTime, 0);
        }
    }, [currentTime, editorRef.current]);


    return (
        <ModernTextEditorInner ref={editorRef}
                               editorState={editorState} corrections={localCorrectionsState}
                               setLocalCorrectionsState={setLocalCorrectionsState}/>
    )
}

const ModernTextEditorInner = forwardRef(function ModernTextEditorInner(
    {
        editorState,
        corrections,
        setLocalCorrectionsState,
    },
    ref,
) {

    const editorRef = useRef(null)
    const [showEditCard, setShowEditCard] = useState(false);
    const [editCardPosition, setEditCardPosition] = useState({x: null, y: null});
    const [isEditSpeakerNode, setIsEditSpeakerNode] = useState(false);
    const [showUndeleteOption, setShowUndeleteOption] = useState(false);
    const [showCorrectOption, setShowCorrectOption] = useState(false);
    const [showSilenceInfoCard, setShowSilenceInfoCard] = useState(false);
    const [silenceInfoCardPosition, setSilenceInfoCardPosition] = useState({x: null, y: null});
    const [silenceInfoDuration, setSilenceInfoDuration] = useState(0);
    const [showCorrectTextBox, setShowCorrectTextBox] = useState(false);
    const [textBoxWidth, setTextBoxWidth] = useState(0);
    const [correctTextBoxPosition, setCorrectTextBoxPosition] = useState({x: null, y: null});
    const [correctTextNodeKey, setCorrectTextNodeKey] = useState(null);
    const [correctedText, setCorrectedText] = useState("");
    const [selectedNodes, setSelectedNodes] = useState([]);

    const [currentNodeElement, setCurrentNodeElement] = useState(null);
    const previousNodeElementRef = useRef(null);

    useEffect(() => {

        if (currentNodeElement) {
            currentNodeElement.style.backgroundColor = 'rgba(255, 0, 0, 0.5)';
        }

        if (previousNodeElementRef.current) {
            previousNodeElementRef.current.style.backgroundColor = 'transparent';
        }
        previousNodeElementRef.current = currentNodeElement;

    }, [currentNodeElement]);

    const saveCorrection = useCreateSaveTranscriptCorrection();

    const initialConfig = {
        namespace: 'Libretto',
        theme: {
            ltr: 'ltr',
            rtl: 'rtl',
            placeholder: 'editor-placeholder',
            paragraph: 'editor-paragraph',
        },
        editable: false,
        onError: (err) => {
            console.error('Error:', err)
        },
        nodes: [ModernSoundNode, HeadingNode],
        // editorState: editorState,
    };

    function getAllSoundNodes() {
        return (
            editorRef.current?.getEditorState().read(() => {
                return $nodesOfType(ModernSoundNode)
            }) ?? []
        )
    }

    function getSilenceCuts(maxSilenceDuration, trimmedSilenceDuration) {
        return (
            editorRef.current?.getEditorState().read(() => {
                const allNodes = getAllSoundNodes();
                const silenceNodes = allNodes.filter((n) => n.isSilenceNode());

                const silenceTrims = [];

                for (let silenceNode of silenceNodes) {
                    const currentDuration = silenceNode.getDuration();

                    // Skip silences shorter than the maxSilence threshold
                    if (currentDuration < maxSilenceDuration) {
                        continue;
                    }

                    // Calculate the total amount to trim to match the target trimmed duration
                    const trimAmount = currentDuration - trimmedSilenceDuration;

                    // Check if trimAmount makes sense (i.e., we're not expanding silence)
                    const newTrim = {
                        startTime: silenceNode.getStart() + (trimmedSilenceDuration / 2),
                        endTime: silenceNode.getEnd() - (trimmedSilenceDuration / 2),
                        layerId: silenceNode.getLayerId(),
                        clipId: silenceNode.getClipId(),
                    };

                    silenceTrims.push(newTrim);
                }

                return silenceTrims;

            }) ?? []
        )
    }

    function getFillerWordCuts() {
        return (
            editorRef.current?.getEditorState().read(() => {
                const allNodes = getAllSoundNodes();
                const fillerWordNodes = allNodes.filter((n) => n.isFillerWord());
                const fillerWordTrims = [];

                for (let i = 0; i < fillerWordNodes.length; i++) {
                    const fillerWordNode = fillerWordNodes[i];
                    const newTrim = {
                        startTime: fillerWordNode.getStart() - 0.01,
                        endTime: fillerWordNode.getEnd() + 0.01,
                        layerId: fillerWordNode.getLayerId(),
                        clipId: fillerWordNode.getClipId(),
                    };
                    fillerWordTrims.push(newTrim);
                }

                return fillerWordTrims;
            }) ?? []
        )
    }

    useImperativeHandle(
        ref,
        () =>
            ({
                recreateEditorState(timelineStateLayers, corrections) {
                    if (!editorRef.current) {
                        return;
                    }

                    editorRef.current?.update(() => {
                        const root = $getRoot()
                        root.clear();
                        BuildOnRoot({timelineStateLayers, corrections, root});
                    });
                },
                refreshEditorState() {
                    if (!editorRef.current) {
                        return;
                    }

                    const timelineStateLayers = ClipsToTimelineItems(Engine.getInstance().getTimeline().getClips());

                    editorRef.current?.update(() => {
                        const root = $getRoot()
                        root.clear();
                        BuildOnRoot({timelineStateLayers, corrections, root});
                    });
                },
                processCorrectionEdit(key, correctedText) {
                    editorRef.current?.update(() => {
                        const node = $getNodeByKey(key);
                        if (node && $isModernSoundNode(node)) {
                            node.setTextContent(correctedText);
                        }
                    });
                },
                updateCurrentTime(time, orderedIndex) {
                    editorRef.current?.update(() => {
                        const allNodes = getAllSoundNodes();
                        const node = allNodes.find((n) => n.getStart() <= time && n.getEnd() >= time);
                        if (node) {
                            const nodeKey = node.getKey();
                            if (!nodeKey) {
                                return;
                            }
                            const element = editorRef.current.getElementByKey(nodeKey);
                            if (element === null) {
                                return;
                            }
                            setCurrentNodeElement(element);
                            element.scrollIntoView({behavior: 'smooth', block: 'center'});
                        } else {
                            // console.log("Node not found for time: ", time, " and ordered index: ", orderedIndex);
                        }
                    });
                },
                getFillerWordCuts,
                getSilenceCuts,
                getAllSoundNodes,
            }),
        [],
    )

    const handleSoundNodeClick = async (event, editor, nodeKey) => {
        const node = $getNodeByKey(nodeKey);
        const clickTime = ((node.getEnd() + node.getStart()) / 2);
        await RendleyService.seek(clickTime);
    }

    const handleSoundNodeMouseOver = (event, editor, nodeKey) => {
        const node = $getNodeByKey(nodeKey);
        // Show silence info card if the node is a silence node.
        if (node && $isModernSoundNode(node) && node.isSilenceNode()) {
            setSilenceInfoDuration(node.getDuration());
            setSilenceInfoCardPosition({x: event.clientX, y: event.clientY});
            setShowSilenceInfoCard(true);
        }

        const element = editor.getElementByKey(nodeKey);
        element.style.backgroundColor = 'rgba(255, 0, 0, 0.1)';
    }

    const handleSoundNodeMouseOut = (event, editor, nodeKey) => {
        setShowSilenceInfoCard(false);
        const element = editor.getElementByKey(nodeKey);
        // Remove the highlight
        element.style.backgroundColor = 'transparent';
    }

    const handleCorrectSelected = () => {
        if (!selectedNodes || selectedNodes.length !== 1) {
            return;
        }

        const nodeKey = selectedNodes[0].key;
        const element = editorRef.current.getElementByKey(nodeKey);
        if (element === null) {
            return;
        }
        const rect = element.getBoundingClientRect();
        const xCoordinate = rect.left + window.scrollX; // X coordinate relative to the document
        const yCoordinate = rect.bottom + window.scrollY;
        setCorrectTextBoxPosition({x: xCoordinate, y: yCoordinate});
        let selectedNode = null;
        editorRef.current.update(() => {
            selectedNode = $getNodeByKey(nodeKey);
        });
        setCorrectedText(selectedNode.getTextContent());
        setCorrectTextNodeKey(nodeKey);
        setShowEditCard(false);
        // Get width of html element
        const elementWidth = element.offsetWidth;
        setTextBoxWidth(elementWidth + 23);
        setShowCorrectTextBox(true);
    }

    const handleCorrectBoxClickAway = () => {
        if (correctedText === "") {
            return;
        }

        editorRef.current.update(() => {
            const selectedNode = $getNodeByKey(correctTextNodeKey);
            if (selectedNode) {
                saveCorrection(selectedNode.getEntityId(), selectedNode.getIndexForCorrection(), correctedText, setLocalCorrectionsState);
                selectedNode.setTextContent(correctedText);
            }
        });
        setShowCorrectTextBox(false);
    }

    const handleDeleteSelected = async () => {
        if (!selectedNodes || selectedNodes.length === 0) {
            return;
        }

        // Sort nodes by start time
        selectedNodes.sort((a, b) => a.start - b.start);

        // Check if all from the same clip
        const clipId = selectedNodes[0].clipId;
        const singleClipCut = selectedNodes.every((node, index, array) => {
            return node.clipId === clipId && clipId !== null;
        });

        const timeline = Engine.getInstance().getTimeline();

        // Check if all the nodes are from the same clip and if so, cut the clip.
        if (singleClipCut) {
            const cutStartTime = selectedNodes[0].start;
            const cutEndTime = selectedNodes[selectedNodes.length - 1].end;
            const clipStartTime = selectedNodes[0].clipStartTime;
            const clipEndTime = selectedNodes[0].clipEndTime;

            // console.log("Cut start time: ", cutStartTime);
            // console.log("Cut end time: ", cutEndTime);
            // console.log("Clip start time: ", clipStartTime);
            // console.log("Clip end time: ", clipEndTime);

            if (Math.abs(clipStartTime - cutStartTime) < 0.5 && Math.abs(clipEndTime - cutEndTime) < 0.5) {
                // console.log("Deleting the whole clip");
                const layer = timeline.getLayerById(selectedNodes[0].layerId);
                const clip = timeline.getClipById(selectedNodes[0].clipId);
                layer.removeClip(clip.id);
                editorRef.current.update(() => {
                    setSelectedNodes([])
                    $setSelection(null);
                });
                setShowEditCard(false);
                return;
            }

            if (Math.abs(clipStartTime - cutStartTime) < 0.5) {
                // console.log("Deleting at the start of the clip");
                let lastClip = timeline.getClipById(selectedNodes[0].clipId);
                const layer = timeline.getLayerById(selectedNodes[0].layerId);
                const nextClip = await layer.splitClip(lastClip.id, cutEndTime);
                layer.removeClip(lastClip.id);

                if (nextClip) {
                    nextClip.moveBy(-1 * cutEndTime);
                }

                editorRef.current.update(() => {
                    setSelectedNodes([])
                    $setSelection(null);
                });
                setShowEditCard(false);
                return;
            }

            if (Math.abs(clipEndTime - cutEndTime) < 0.5) {
                // console.log("Deleting at the end of the clip");
                let firstClip = timeline.getClipById(selectedNodes[0].clipId);
                const layer = timeline.getLayerById(selectedNodes[0].layerId);
                const badClip = await layer.splitClip(firstClip.id, cutStartTime);
                if (badClip) {
                    layer.removeClip(badClip.id);
                }

                editorRef.current.update(() => {
                    setSelectedNodes([])
                    $setSelection(null);
                });
                setShowEditCard(false);
                return;
            }


            let currentClip = timeline.getClipById(selectedNodes[0].clipId);
            const layer = timeline.getLayerById(selectedNodes[0].layerId);
            const badClip = await layer.splitClip(currentClip.id, cutStartTime);

            if (!badClip) {
                editorRef.current.update(() => {
                    setSelectedNodes([])
                    $setSelection(null);
                });

                setShowEditCard(false);
                return;
            }

            const nextClip = await layer.splitClip(badClip.id, cutEndTime);
            layer.removeClip(badClip.id);
            nextClip.moveBy(currentClip.getRightBound() - nextClip.getLeftBound());

            editorRef.current.update(() => {
                setSelectedNodes([])
                $setSelection(null);
            });

            setShowEditCard(false);

            return;
        }

        // Put nodes of the same clip in the same array, in a map keyed by clip id.
        const nodesPerClip = new Map();

        for (let node of selectedNodes) {
            const fetchedNode = node;
            if (fetchedNode) {
                const clipId = fetchedNode.clipId;
                if (!clipId) {
                    continue;
                }
                if (!nodesPerClip.has(clipId)) {
                    nodesPerClip.set(clipId, []);
                }
                nodesPerClip.get(clipId).push(fetchedNode);
            }
        }


        const clipCuts = new Map();

        for (let [clipId, nodes] of nodesPerClip) {
            let startTime = nodes[0].start;
            let endTime = nodes[0].end;
            let layerId = nodes[0].layerId;

            const remaningNodes = nodes.slice(1);

            // If the endTime of the last node is within 0.1 sec of the startTime of the next node,
            // keep extending the endTime so that we make a single [startTime, endTime] cut.
            // If the gap is too large, push the cut into layerCuts and start a new cut.
            for (let node of remaningNodes) {
                if (node.start - endTime <= 0.1) {
                    endTime = node.end;
                } else {
                    if (!clipCuts.has(clipId)) {
                        clipCuts.set(clipId, []);
                    }
                    clipCuts.get(clipId).push({startTime, endTime, layerId});
                    startTime = node.start;
                    endTime = node.end;
                    layerId = node.layerId;
                }
            }

            // Push the last cut for each layer
            if (!clipCuts.has(clipId)) {
                clipCuts.set(clipId, []);
            }
            clipCuts.get(clipId).push({startTime, endTime, layerId});
        }

        // Apply the cuts
        for (let [clipId, cuts] of clipCuts) {
            let lastClip = timeline.getClipById(clipId);
            const layer = timeline.getLayerById(cuts[0].layerId);
            const clipList = [lastClip];
            for (let cut of cuts) {
                const badClip = await layer.splitClip(lastClip.id, cut.startTime);
                lastClip = await layer.splitClip(badClip.id, cut.endTime);
                layer.removeClip(badClip.id);
                clipList.push(lastClip);
            }

            for (let i = 0; i < clipList.length - 1; i++) {
                const currentClip = clipList[i];
                const nextClip = clipList[i + 1];
                nextClip.moveBy(currentClip.getRightBound() - nextClip.getLeftBound());
            }
        }

        editorRef.current.update(() => {
            setSelectedNodes([])
            $setSelection(null);
        });

        setShowEditCard(false);
    }

    const underlineNode = (nodeKey, editor) => {
        const element = editor.getElementByKey(nodeKey);
        if (element === null) {
            return;
        }
        if (element.style.textDecoration === '') {
            element.style.textDecoration = 'underline';
            return false;
        }
        return element.style.textDecoration === 'line-through';
    }

    const removeUnderlineForNode = (nodeKey, editor) => {
        const element = editor.getElementByKey(nodeKey);
        if (element === null) {
            return;
        }
        if (element.style.textDecoration === 'underline') {
            element.style.textDecoration = '';
        }
    }

    const handleNodesSelected = (nodes, editor) => {
        // Unselect previously selected nodes
        for (let node of selectedNodes) {
            if (node.key) {
                removeUnderlineForNode(node.key, editor);
            }
        }
        setSelectedNodes(nodes);
        if (!nodes || nodes.length === 0) {
            setShowEditCard(false);
            return;
        }

        // Underline the selected nodes.
        let allDeleted = false;
        for (let node of nodes) {
            if (node.key) {
                underlineNode(node.key, editor);
                const fetchedNode = $getNodeByKey(node.key);
                // If the selected node is a speaker node and there are more than one node selected, don't show the edit card.
                if (fetchedNode && fetchedNode.isSpeakerNode() && nodes.length > 1) {
                    return;
                }
            }
        }

        // Use the location of the first node to position the edit card.
        const firstNodeKey = nodes[0].key;
        const element = editor.getElementByKey(firstNodeKey);
        if (element === null) {
            return;
        }
        const rect = element.getBoundingClientRect();
        const xCoordinate = rect.left + window.scrollX; // X coordinate relative to the document
        const yCoordinate = rect.top + window.scrollY;  // Y coordinate relative to the document

        const firstNode = $getNodeByKey(firstNodeKey);
        const shouldShowCorrectOption = firstNode ? nodes.length === 1 && firstNode != null && !firstNode.isSilenceNode() : false;
        const isSpeakerNode = nodes.length === 1 && firstNode != null && firstNode.isSpeakerNode();
        setShowUndeleteOption(allDeleted && nodes.length > 0);
        setEditCardPosition({x: xCoordinate, y: yCoordinate});
        setIsEditSpeakerNode(isSpeakerNode);
        setShowCorrectOption(shouldShowCorrectOption);
        setShowEditCard(true);
    }

    const handleSelectionChange = debounce(function handleSelectionChange(
            editorState, editor
        ) {
            editorState.read(() => {
                const selection = $getSelection()

                if (!selection) {
                    handleNodesSelected([], editor)
                    return
                }

                const selectedNodes = selection.getNodes()
                const soundNodes = selectedNodes.filter($isModernSoundNode)

                const rawLocs = soundNodes.map((l) => l.getSoundLocation())
                handleNodesSelected(rawLocs, editor)
            })
        },
        150)

    return (
        <LexicalComposer initialConfig={initialConfig}>
            <Container
                bg="white"
                // my="1"
                py="0"
                px="8"
                fontSize="17px"
                fontWeight="normal"
                style={{
                    overflowY: 'auto',
                    margin: 0,
                    padding: 0,
                    display: 'flex',
                    justifyContent: 'center',
                    backgroundColor: "white",
                    borderRadius: "12px"
                }}
            >
                <RichTextPlugin
                    contentEditable={
                        <ContentEditable style={editorInputStyle} spellCheck={false}/>
                    }
                    ErrorBoundary={LexicalErrorBoundary}
                    placeholder={""}/>
                <OnChangePlugin onChange={handleSelectionChange}/>
                <NodeEventPlugin nodeType={ModernSoundNode} eventType={'mouseover'}
                                 eventListener={handleSoundNodeMouseOver}/>
                <NodeEventPlugin nodeType={ModernSoundNode} eventType={'mouseout'}
                                 eventListener={handleSoundNodeMouseOut}/>
                <NodeEventPlugin nodeType={ModernSoundNode} eventType={'click'} eventListener={handleSoundNodeClick}/>
                <HistoryPlugin/>
                <RefPlugin editorRef={editorRef}/>
                <MarkdownShortcutPlugin transformers={[HEADING]}/>
                {showCorrectTextBox &&
                    <CorrectTextBox xPosition={correctTextBoxPosition.x} yPosition={correctTextBoxPosition.y}
                                    correctedText={correctedText} setCorrectedText={setCorrectedText}
                                    width={textBoxWidth} handleClickaway={handleCorrectBoxClickAway}/>}
                {showSilenceInfoCard &&
                    <SilenceInfoCard xPosition={silenceInfoCardPosition.x} yPosition={silenceInfoCardPosition.y}
                                     durationSecs={silenceInfoDuration}/>}
                {showEditCard && <EditSelectedCard
                    isSpeakerNode={isEditSpeakerNode}
                    selectedNodeX={editCardPosition.x}
                    selectedNodeY={editCardPosition.y}
                    showUndeleteOption={showUndeleteOption}
                    handleDelete={handleDeleteSelected}
                    showCorrectOption={showCorrectOption}
                    handleCorrect={handleCorrectSelected}/>}
            </Container>
        </LexicalComposer>
    )
})

export default ModernTextEditor;