import React, {useState} from 'react';
import {Box, Button, Card, CardContent, CardActions, Typography, useTheme} from '@mui/material';
import {KAppSumoCodeLength, LibrettoPlan} from "../../utils/utils";
import TextField from "@mui/material/TextField";
import {NewFeatureLine} from "../Pricing/Pricing";

const PlanDetails = {
    [LibrettoPlan.Free]: {name: "Free", monthlyPrice: "$0", annualPrice: "$0", annualTotal: "$0"},
    [LibrettoPlan.StandardAnnual]: {name: "Standard", monthlyPrice: "$15", annualPrice: "$12", annualTotal: "$144"},
    [LibrettoPlan.StandardMonthly]: {name: "Standard", monthlyPrice: "$15", annualPrice: "$12", annualTotal: "$144"},
    [LibrettoPlan.CreatorAnnual]: {name: "Creator", monthlyPrice: "$24", annualPrice: "$20", annualTotal: "$240"},
    [LibrettoPlan.CreatorMonthly]: {name: "Creator", monthlyPrice: "$24", annualPrice: "$20", annualTotal: "$240"},
};

const FeatureLineNoIcon = ({highlightText, text, hidden}) => {
    if (hidden) return null;
    return (
        <Typography variant="body2" style={{marginBottom: '8px', color: 'rgba(26, 26, 26, 0.8)'}}>
            <span style={{fontWeight: 'bold', color: '#1a1a1a'}}>{highlightText}</span> {text}
        </Typography>
    );
};

const FeatureLineEmpty = () => {
    return (
        <Typography variant="body2" style={{marginBottom: '8px', color: 'rgba(26, 26, 26, 0.8)'}}>
            &nbsp;
        </Typography>
    );
}

const PricingCard = ({planType, currentPlan, isAnnual, onChangePlan}) => {
    const theme = useTheme();
    const planDetails = PlanDetails[planType];

    const cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: 350,
        height: '100%',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        border: planType === LibrettoPlan.StandardMonthly || planType === LibrettoPlan.StandardAnnual ? `2px solid #2B6BFD` : `2px solid transparent`,
    };

    const isCurrentPlan = planType === currentPlan;
    const price = isAnnual ? planDetails.annualPrice : planDetails.monthlyPrice;

    return (
        <Card style={cardStyle}>
            <CardContent style={{flexGrow: 1, padding: '24px', display: 'flex', flexDirection: 'column'}}>
                <Typography variant="h5" fontWeight={700} marginBottom={2} color="#1a1a1a">
                    {planDetails.name}
                </Typography>
                <Typography fontWeight={600} variant="subtitle1" color="text.secondary" marginBottom={2}>
                    {planDetails.name === "Free" ? "Start your journey" :
                        planDetails.name === "Standard" ? "Recording and editing essentials" :
                            "Recording and editing suite"}
                </Typography>
                <Typography fontWeight={600} color="#1a1a1a" marginBottom={1}>
                    <span style={{fontSize: '2rem', fontWeight: 900}}>{price}</span>/month
                </Typography>
                {isAnnual && planType != LibrettoPlan.Free && (
                    <Typography color="rgba(26, 26, 26, 0.7)" marginBottom={2}>
                        {planDetails.annualTotal} billed annually
                    </Typography>
                )}
                {!isAnnual || planType == LibrettoPlan.Free ? (
                    <Typography color="rgba(26, 26, 26, 0.7)" marginBottom={2}>
                        &nbsp;
                    </Typography>
                ) : null}
                <Box marginTop={2} style={{flexGrow: 1}}>
                    {planDetails.name === "Free" && (
                        <>
                            <NewFeatureLine text={"1 transcription hour / month"}/>
                            <NewFeatureLine text={"Cloud audio & video recording"}/>
                            <NewFeatureLine text={"Export upto 720p res video"}/>
                            <NewFeatureLine text={"Limited trial of recording features"}/>
                            <NewFeatureLine text={"Limited trial of editor features"}/>
                            <br/>
                            <br/>
                        </>
                    )}
                    {planDetails.name === "Standard" && (
                        <>
                            <NewFeatureLine text={"10 transcription hours / month"}/>
                            <NewFeatureLine text={"Unlimited, high-quality screen & remote recording"}/>
                            <NewFeatureLine text={"Export upto 1080p res video"}/>
                            <NewFeatureLine text={"25 uses / month of AI editing features including Filler Word Removal and Studio Sound"}/>
                        </>
                    )}
                    {planDetails.name === "Creator" && (
                        <>
                            <NewFeatureLine text={"30 transcription hours / month"}/>
                            <NewFeatureLine text={"Unlimited, high-quality screen & remote recording"}/>
                            <NewFeatureLine text={"Export upto 1080p res video"}/>
                            <NewFeatureLine text={"Unlimited use of AI editing features including Filler Word Removal and Studio Sound"}/>
                        </>
                    )}
                </Box>
            </CardContent>
            <CardActions style={{padding: '16px 24px 24px', marginTop: 'auto'}}>
                <Button
                    variant="contained"
                    fullWidth
                    disabled={isCurrentPlan}
                    sx={{
                        backgroundColor: isCurrentPlan ? '#e0e0e0' : '#2B6BFD',
                        color: isCurrentPlan ? '#9e9e9e' : '#ffffff',
                        borderRadius: '10px',
                        "&:hover": {
                            background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
                        },
                        // Set focused state
                        "&:focus": {
                            background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
                        },
                        // Set disabled state
                        "&:disabled": {
                            cursor: "not-allowed",
                        }
                    }}
                    onClick={() => onChangePlan(planType)}
                >
                    <Typography fontWeight={700}>
                        {isCurrentPlan ? "Current Plan" : `Change to ${planDetails.name}`}
                    </Typography>
                </Button>
            </CardActions>
        </Card>
    );
};

const UpgradePricingBox = ({currentPlan, onChangePlan}) => {
    const [isAnnual, setIsAnnual] = React.useState(true);

    const renderToggler = () => (
        <Box sx={{display: 'flex', alignItems: 'center', gap: 2, justifyContent: 'center', marginBottom: 4}}>
            <Button
                variant={isAnnual ? 'contained' : 'outlined'}
                onClick={() => setIsAnnual(true)}
                sx={{
                    width: 170,
                    borderRadius: '15px',
                    height: '35px',
                    backgroundColor: isAnnual ? '#2B6BFD' : 'transparent',
                    color: isAnnual ? '#ffffff' : '#2B6BFD'
                }}
            >
                <Typography variant="h6" fontWeight={600}>Annual</Typography>
            </Button>
            <Button
                variant={!isAnnual ? 'contained' : 'outlined'}
                onClick={() => setIsAnnual(false)}
                sx={{
                    width: 170,
                    borderRadius: '15px',
                    height: '35px',
                    backgroundColor: !isAnnual ? '#2B6BFD' : 'transparent',
                    color: !isAnnual ? '#ffffff' : '#2B6BFD'
                }}
            >
                <Typography variant="h6" fontWeight={600}>Monthly</Typography>
            </Button>
        </Box>
    );

    return (
        <Box sx={{backgroundColor: '#f5f5f5', padding: 4, borderRadius: '20px'}}>
            <Typography variant="h4" align="center" fontWeight={700} color="#1a1a1a" marginBottom={3}>
                Change Your Plan
            </Typography>
            {renderToggler()}
            <Box sx={{display: 'flex', justifyContent: 'center', gap: 3, flexWrap: 'wrap'}}>
                <PricingCard
                    planType={isAnnual ? LibrettoPlan.Free : LibrettoPlan.Free}
                    currentPlan={currentPlan}
                    isAnnual={isAnnual}
                    onChangePlan={onChangePlan}
                />
                <PricingCard
                    planType={isAnnual ? LibrettoPlan.StandardAnnual : LibrettoPlan.StandardMonthly}
                    currentPlan={currentPlan}
                    isAnnual={isAnnual}
                    onChangePlan={onChangePlan}
                />
                <PricingCard
                    planType={isAnnual ? LibrettoPlan.CreatorAnnual : LibrettoPlan.CreatorMonthly}
                    currentPlan={currentPlan}
                    isAnnual={isAnnual}
                    onChangePlan={onChangePlan}
                />
            </Box>
        </Box>
    );
};

const FeatureLineHeader = ({text}) => (
    <Typography variant="subtitle1" fontWeight={600} style={{marginTop: '16px', marginBottom: '8px', color: '#1a1a1a'}}>
        {text}
    </Typography>
);

export const LifetimeUpgradePricingBox = ({onUpgrade}) => {
    const theme = useTheme();
    const [upgradeCode, setUpgradeCode] = useState('');

    const handleCodeChange = (event) => {
        setUpgradeCode(event.target.value);
    };

    const handleUpgradeClick = () => {
        onUpgrade(upgradeCode);
    };

    const cardStyle = {
        display: 'flex',
        flexDirection: 'column',
        width: 350,
        height: '100%',
        borderRadius: '15px',
        backgroundColor: '#ffffff',
        border: `2px solid #2B6BFD`,
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    };

    const upgradeButtonDisabled = upgradeCode.length !== KAppSumoCodeLength;

    return (
        <Box sx={{backgroundColor: '#f5f5f5', padding: 4, borderRadius: '20px'}}>
            <Typography variant="h4" align="center" fontWeight={700} color="#1a1a1a" marginBottom={3}>
                Upgrade Your Plan
            </Typography>
            <Box sx={{display: 'flex', justifyContent: 'center', gap: 3, flexWrap: 'wrap'}}>
                <Card style={cardStyle}>
                    <CardContent style={{flexGrow: 1, padding: '24px', display: 'flex', flexDirection: 'column'}}>
                        <Typography variant="h5" fontWeight={700} marginBottom={2} color="#1a1a1a">
                            Creator Lifetime Tier 2
                        </Typography>
                        <Typography fontWeight={600} variant="subtitle1" color="rgba(26, 26, 26, 0.7)" marginBottom={2}>
                            Upgrade your lifetime plan
                        </Typography>

                        <Box marginTop={2} style={{flexGrow: 1}}>
                            <FeatureLineHeader text="Record audio & video"/>
                            <FeatureLineNoIcon highlightText="30 Hours" text="separate track recording / month"/>
                            <FeatureLineNoIcon highlightText="Hi-Fi" text="48khz 16-bit raw audio recording"/>
                            <FeatureLineNoIcon highlightText="High-quality" text="local video recording"/>
                            <FeatureLineNoIcon highlightText="Up to 4K" text="resolution video"/>
                            <FeatureLineNoIcon highlightText="Unlimited" text="single track recording"/>
                            <FeatureLineNoIcon highlightText="Unlimited storage" text="for recordings"/>

                            <FeatureLineHeader text="Edit, transcribe, caption, post-produce"/>
                            <FeatureLineNoIcon highlightText="30 hours audio enhance" text="credits / month"/>
                            <FeatureLineNoIcon highlightText="30 transcription hours" text="/ month"/>
                            <FeatureLineNoIcon highlightText="Collaborative audio & video editor"/>
                            <FeatureLineNoIcon highlightText="100GB storage" text="for uploads"/>
                            <FeatureLineNoIcon highlightText="Live chat" text="support"/>
                        </Box>
                    </CardContent>
                    <CardActions style={{padding: '16px 24px 24px', marginTop: 'auto'}}>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                width: '100%'
                            }}
                        >
                            <Typography variant="subtitle2" fontWeight={600} marginBottom={1}>
                                Enter upgrade code
                            </Typography>
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={upgradeCode}
                                onChange={handleCodeChange}
                                placeholder="Enter your code"
                                style={{marginBottom: '15px'}}
                                InputProps={{
                                    style: {height: '40px'}
                                }}
                            />
                            <Button
                                variant="contained"
                                fullWidth
                                disabled={upgradeButtonDisabled}
                                style={{
                                    backgroundColor: !upgradeButtonDisabled ? '#2B6BFD' : '#e0e0e0',
                                    color: !upgradeButtonDisabled ? '#ffffff' : '#9e9e9e',
                                    borderRadius: '10px',
                                }}
                                onClick={handleUpgradeClick}
                            >
                                <Typography fontWeight={700}>Upgrade Plan</Typography>
                            </Button>
                        </Box>
                    </CardActions>
                </Card>
            </Box>
        </Box>
    );
};


export default UpgradePricingBox;
