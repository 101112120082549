import './Option.styles.scss';

const Option = ({label, value, optionStyle, optionSelected, children }) => {

    return (
        <li className="option" style={optionStyle} onClick={() => optionSelected({label, value})}>
            {children}
        </li>
    )

}

export default Option;