import React, {useState, useEffect, useRef, useCallback, useContext} from 'react';
import {observer} from 'mobx-react-lite';
import {ClipTypeEnum, Engine} from "@rendley/sdk";
import {RendleyService} from "../../../../services/RendleyService";
import {RendleyStore} from "../../../../store/RendleyStore";
// @ts-ignore
import './MediaPanelContainer.styles.scss';
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {
    determineTrackThumbnailUrl, EditorMediaTypeEnums,
    formatToReadableShortTitle,
    formatUnixTime, IsUploadedTrackType, IsVideoTrackType, loadTranscriptForTrack,
    RefreshTokenAndRetry
} from "../../../../../utils/utils";
import {ButtonGroup, Button, Card, CardActionArea, CardContent, Grid, DialogContent} from "@mui/material";
import {FetchContext} from "../../../../../context/FetchContext";
import {AuthContext} from "../../../../../context/AuthContext";
import EditorMediaThumbnail from "../../../../../pages/ModernEditor/EditorThumbnail";
import {useConfirmDialog} from "../../../../../pages/NewHome/DeleteConfirmation";
import {SortButton} from "../../../../../pages/NewHome/HomeButtons";

interface MediaPanelContainerProps {
    onClose?: () => void;
    projectTracks: any;
    projectImages: any;
    // @ts-ignore
}

interface Track {
    assetId?: string; // Make sure to include this if optional
    trackId?: string;
    thumbnailUrl?: string;
    title?: string;
    status?: string;
    duration?: number;
}

const MediaPanelContainer: React.FC<MediaPanelContainerProps> = observer(({projectTracks, projectImages}) => {
    const fetchContext = useContext(FetchContext);
    const authContext = useContext(AuthContext);

    const [loadingStates, setLoadingStates] = useState({});

    const [sortedTracks, setSortedTracks] = useState<Track[] | null>(null);
    const [sortNewest, setSortNewest] = useState(true);

    const [selectedTab, setSelectedTab] = useState(EditorMediaTypeEnums.VIDEO);

    const {openDialog, ConfirmDialogComponent} = useConfirmDialog({confirmText: 'Delete', cancelText: 'Cancel'});

    const deleteTrack = async ({trackId}) => {
        fetchContext.authAxios.post(`/tracks/delete`, {
            trackIds: [trackId],
        }, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            }
        }).then(() => {
        }).catch((err) => {
            if (err.response.status === 401) {
                RefreshTokenAndRetry(err, authContext, fetchContext);
            }
        })
    }

    const deleteAsset = async ({assetId}) => {
        fetchContext.authAxios.delete(`/assets/${assetId}`, {
            headers: {
                Authorization: `Bearer ${authContext.getToken()}`,
            }
        }).then(() => {

        }).catch((err) => {
            if (err.response.status === 401) {
                RefreshTokenAndRetry(err, authContext, fetchContext);
            }
        })
    };

    const handleDeleteClick = ({track, isImage}) => {

        if (track.status !== "Ready") {
            return;
        }

        openDialog({
            title: 'Delete this ' + (isImage ? 'image' : 'track') + '?',
            message: 'It will be permanently deleted.',
            onConfirm: async () => {
                if (isImage) {
                    await deleteAsset({assetId: track.assetId});
                } else {
                    await deleteTrack({trackId: track.trackId});
                }
            },
        });
    }

    useEffect(() => {
        const assetsToSort = selectedTab === EditorMediaTypeEnums.IMAGE ? projectImages : projectTracks;

        if (!assetsToSort) return;

        const sorted = [...assetsToSort].sort((a, b) => {
            // @ts-ignore
            return sortNewest ? b.createTime - a.createTime : a.createTime - b.createTime;
        });
        // @ts-ignore
        setSortedTracks(sorted);
    }, [projectTracks, sortNewest, projectImages, selectedTab]);

    const handleSetSelectedTab = (tab) => {
        setSelectedTab(tab);
        const assetsToSort = tab === EditorMediaTypeEnums.IMAGE ? projectImages : projectTracks;
        const sorted = [...assetsToSort].sort((a, b) => {
            // @ts-ignore
            return sortNewest ? b.createTime - a.createTime : a.createTime - b.createTime;
        });
        // @ts-ignore
        setSortedTracks(sorted);
    }


    const handleThumbnailClick = useCallback(async ({track, isImage}) => {

        if (track.status !== "Ready") {
            return;
        }

        const entityId = isImage ? track.assetId : track.trackId;
        setLoadingStates((prevState) => ({
            ...prevState,
            [entityId]: true,
        }));
        console.log("Is loading: ", true);

        await handleClickMediaThumbnail({track, isImage});

        setLoadingStates((prevState) => ({
            ...prevState,
            [entityId]: false,
        }));
        console.log("Is loading: ", false);
    }, []);


    const handleClickMediaThumbnail = useCallback(async ({track, isImage}) => {

        if (track.status !== "Ready") {
            return;
        }

        const entityId = isImage ? track.assetId : track.trackId;

        let mediaDataId = null;

        // Try and find the media in the library.
        if (Engine.getInstance().getLibrary().media) {
            const media = Engine.getInstance().getLibrary().media;

            Object.entries(media)?.forEach(([key, value]) => {
                value.customData?.forEach((customDataValue, customDataKey) => {
                    if (customDataKey === "entityId" && customDataValue === entityId) {
                        mediaDataId = key;
                        return;
                    }
                });
                if (mediaDataId != null) return;
            });
        }

        // If not already there, add the media to the library.
        if (!mediaDataId) {
            try {
                const objectUrl = isImage ? track.thumbnailUrl : track.renditionUrl ? track.renditionUrl : track.objectUrl;
                const audioExtension = !isImage && !IsVideoTrackType(track.trackType) ? track.objectName.split('.').pop() : null;
                const mimeType = isImage ? "image/png" : IsVideoTrackType(track.trackType) ? "video/mp4" : `audio/${audioExtension}`;

                // Parallelize the fetching of the object and the transcript
                const fetchObject = fetch(objectUrl).then((response) => response.blob());
                const fetchTranscript = (!isImage && track.transcriptUrl != null && track.hasTranscript && !track.isSilentAudio) ?
                    fetch(track.transcriptUrl).then((response) => response.json()).catch(() => {
                        return null;
                    }) : null;

                const [blob, transcript] = await Promise.all([fetchObject, fetchTranscript]);

                const file = new File([blob], track.title, {type: mimeType});

                // Add media to gallery
                mediaDataId = await RendleyService.addMediaToGallery(file, mimeType);
                if (mediaDataId == null) return;
                RendleyService.getMediaById(mediaDataId)?.setPermanentUrl(objectUrl);
                RendleyService.getMediaById(mediaDataId)?.setCustomData("entityId", entityId);
                RendleyService.getMediaById(mediaDataId)?.setCustomData("transcriptUrl", track.transcriptUrl);
                RendleyService.getMediaById(mediaDataId)?.setCustomData("transcript", transcript);

                window.dispatchEvent(new CustomEvent("LIBRETTO_LIBRARY_ADDED", {
                    detail: {
                        filename: track.title,
                        id: mediaDataId,
                        permanentUrl: objectUrl,
                        mimeType: mimeType,
                        entityId: entityId,
                        transcriptUrl: track.hasTranscript ? track.transcriptUrl : "",
                    }
                }));
            } catch (error) {
                console.error("Error adding media to gallery: ", error);
            }
        } else {
            console.log("Media already in library.");
        }

        // Add the clip to the timeline.
        try {
            const layer = RendleyService.createLayer();
            const clip = await RendleyService.addMediaToLayer(layer.id, mediaDataId, 0);
            if (clip == null) {
                return
            }
            RendleyService.getClipById(clip.id)?.setCustomData("layerId", layer.id);
            RendleyStore.setFilenameByClipId(clip.id, track.title);
        } catch (error) {
            console.error(error);
        }
        }, []);

    const filteredProjectTracks = sortedTracks ? sortedTracks.filter((track) => {
        if (selectedTab === EditorMediaTypeEnums.VIDEO) { // @ts-ignore
            return IsVideoTrackType(track.trackType);
        } else if (selectedTab === EditorMediaTypeEnums.AUDIO) { // @ts-ignore
            return !IsVideoTrackType(track.trackType);
        } else if (selectedTab === EditorMediaTypeEnums.IMAGE) {
            return true;
        }
    }) : [];

    const readableMediaType = (selectedTab) => {
        if (selectedTab === EditorMediaTypeEnums.VIDEO) {
            return "videos";
        } else if (selectedTab === EditorMediaTypeEnums.AUDIO) {
            return "audio";
        } else {
            return "images";
        }
    }

    // @ts-ignore
    return (
        <div style={{width: '100%', maxHeight: '600px', minWidth: "250px"}}>
            <ConfirmDialogComponent/>
            <Box
                display="flex"
                alignItems="center"
                flexDirection="row"
                width="100%"
            >
                <Box display="flex">
                    <Button
                        variant="text"
                        onClick={() => handleSetSelectedTab(EditorMediaTypeEnums.IMAGE)}
                        sx={{
                            color: '#2B6BFD',
                            borderBottom: selectedTab === EditorMediaTypeEnums.IMAGE ? '2px solid #2B6BFD' : 'none',
                            borderRadius: '0px',
                        }}
                    >
                        <Typography fontWeight={400}>Image</Typography>
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => handleSetSelectedTab(EditorMediaTypeEnums.VIDEO)}
                        sx={{
                            color: '#2B6BFD',
                            borderBottom: selectedTab === EditorMediaTypeEnums.VIDEO ? '2px solid #2B6BFD' : 'none',
                            borderRadius: '0px',
                        }}
                    >
                        <Typography fontWeight={400}>Video</Typography>
                    </Button>
                    <Button
                        variant="text"
                        onClick={() => handleSetSelectedTab(EditorMediaTypeEnums.AUDIO)}
                        sx={{
                            color: '#2B6BFD',
                            borderBottom: selectedTab === EditorMediaTypeEnums.AUDIO ? '2px solid #2B6BFD' : 'none',
                            borderRadius: '0px',
                        }}
                    >
                        <Typography fontWeight={400}>Audio</Typography>
                    </Button>
                </Box>
                <Box marginLeft="auto">
                    <SortButton sortNewest={sortNewest} onClick={() => setSortNewest(!sortNewest)} iconOnly={true}/>
                </Box>
            </Box>
            <div>
                <br/>
                {filteredProjectTracks && filteredProjectTracks.length > 0 ? (
                    <Grid container spacing={2}>
                        {filteredProjectTracks.map((track) => (
                            <Grid item xs={6}
                                  key={selectedTab === EditorMediaTypeEnums.IMAGE ? track.assetId : track.trackId}>
                                {/* @ts-ignore */}
                                <EditorMediaThumbnail
                                    thumbnailUrl={track.thumbnailUrl}
                                    title={track.title}
                                    status={track.status}
                                    isImage={selectedTab === EditorMediaTypeEnums.IMAGE}
                                    isVideo={selectedTab === EditorMediaTypeEnums.VIDEO}
                                    isLoading={selectedTab === EditorMediaTypeEnums.IMAGE ? !!loadingStates[track.assetId] : !!loadingStates[track.trackId]}
                                    duration={selectedTab === EditorMediaTypeEnums.IMAGE ? null : track.duration}
                                    onClick={() => handleThumbnailClick({
                                        track,
                                        isImage: selectedTab === EditorMediaTypeEnums.IMAGE
                                    })}
                                    onDelete={() => handleDeleteClick({
                                        track,
                                        isImage: selectedTab === EditorMediaTypeEnums.IMAGE
                                    })}
                                />
                            </Grid>
                        ))}
                    </Grid>
                ) : (
                    <Box>
                        <Typography align="center">No {readableMediaType(selectedTab)} found.</Typography>
                    </Box>
                )}
            </div>
        </div>
    );
});

export default MediaPanelContainer;