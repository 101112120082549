import React, { createContext } from 'react';
import axios from 'axios';

const FetchContext = createContext();
const { Provider } = FetchContext;

const isProduction = true;

const kIsProduction = "isProduction";

sessionStorage.setItem(kIsProduction, String(isProduction));

const LOCAL_BASE_URL = "https://localhost:8088";

const PROD_BASE_URL = "https://cdn.libretto.fm";

const LOCAL_CLIENT_URL = "http://localhost:3000";

const PROD_CLIENT_URL = "https://libretto.fm"

const CLIENT_URL = isProduction ? PROD_CLIENT_URL : LOCAL_CLIENT_URL;

const BASE_URL = isProduction ? PROD_BASE_URL : LOCAL_BASE_URL;

const FetchProvider = ({ children }) => {
    const authAxios = axios.create({
        baseURL: BASE_URL,
    });

    const getBaseURL = () => {
        return BASE_URL;
    };

    const getProxiedURL = (url) => {
        // if (!url) return "";
        // const baseUrl = isProduction ? BASE_URL : LOCAL_BASE_URL;
        // const encodedUrl = encodeURIComponent(url);
        // return `${baseUrl}/proxy/${encodedUrl}`;
        return url;
    }

    const proxiedURL = (url) => {
        if (!url) return "";
        const baseUrl = isProduction ? BASE_URL : LOCAL_BASE_URL;
        const encodedUrl = encodeURIComponent(url);
        return `${baseUrl}/proxy/${encodedUrl}`;
    }

    return (
        <Provider
            value={{
                authAxios,
                getBaseURL,
                proxiedURL,
                getProxiedURL,
            }}
        >
            {children}
        </Provider>
    );
};

export { FetchContext, FetchProvider, BASE_URL, CLIENT_URL };
