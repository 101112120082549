import React from 'react';
import { Button, Typography } from '@mui/material';
import {HourglassBottom} from "@mui/icons-material";

const LoadingButton = ({ onClick, disabled, isLoading }) => {
    return (
        <Button
            variant="contained"
            style={{ borderRadius: "5px", height: "35px", width: "85px" }}
            onClick={onClick}
            disabled={disabled}
        >
            {isLoading ? <HourglassBottom /> : <Typography color="white">Apply</Typography>}
        </Button>
    );
};

export default LoadingButton;