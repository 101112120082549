import React from 'react';
import { Dialog, DialogContent, Typography } from '@mui/material';
import './LoadingDialog.css';

function LoadingDialog({ isLoading, text = 'Loading' }) {
    return (
        <Dialog
            open={isLoading}
            onClose={() => {}}
            aria-labelledby="form-dialog-title"
        >
            <DialogContent
                sx={{
                    borderRadius: '30px',
                    display: 'flex',          // Enable flexbox
                    justifyContent: 'center',  // Center horizontally
                    alignItems: 'center',      // Center vertically
                    minWidth: { xs: '300px', md: '400px' },
                }}
            >
                <Typography
                    variant="h6"
                    sx={{
                        animation: 'pulse 1.5s infinite', // Add the pulsing animation
                        '@keyframes pulse': {
                            '0%': { opacity: 1 },
                            '50%': { opacity: 0.5 },
                            '100%': { opacity: 1 },
                        },
                    }}
                >
                    {text}...
                </Typography>
            </DialogContent>
        </Dialog>
    );
}

export default LoadingDialog;
