import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import DraftEditCard from "./DraftEditCard";
import React, {useEffect, useState} from "react";
import {formatDuration, formatToReadableShortTitle, formatUnixTime, IsVideoTrackType} from "../../utils/utils";
import {AudioWaveformIcon, VideoCameraIcon} from "../NewHome/ProjectThumbnail";
import {Box} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {Close} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";


export const DraftEditsView = ({draftEdits, assetId, navigate, deleteEdit}) => {

    return (
        <>
            <Grid item>
                <Typography variant="subtitle1" fontWeight={700} fontSize="1.1rem">Draft edits</Typography>
            </Grid>
            <Grid container item direction="row" spacing={2} id="draft-edits">
                {draftEdits.map((item, i) => (
                    <Grid item>
                        <DraftEditCard assetId={assetId} editId={item.editId} title={item.title}
                                       createdAt={item.createdAt} navigate={navigate} deleteEdit={deleteEdit}/>
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

export const DraftEditsCards = ({draftEdits, editSummaries, assetId, navigate, deleteEdit}) => {

    const titleStyle = {
        fontFamily: 'Inter',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '24px',
        marginBottom: "20px",
    };

    return (
        <>
            <Box sx={titleStyle}>
                Draft edits
            </Box>
            <Grid container item direction="row" spacing={2} id="draft-edits">
                {draftEdits.map((edit, i) => (
                    <Grid item>
                        {editSummaries && editSummaries.get(edit.editId) && <DraftThumbnailCard title={edit.title} createTime={edit.createdAt}
                                            editSummary={editSummaries ? editSummaries.get(edit.editId) : null} onClick={() => navigate(`/edit/${edit.editId}`)} onDelete={deleteEdit}/>}
                    </Grid>
                ))}
            </Grid>
        </>
    )
}

const DraftThumbnailCard = ({title, createTime, editSummary, onClick, onDelete}) => {
    const [aspectRatio, setAspectRatio] = useState('16:9');

    const thumbnailUrl = editSummary.thumbnailUrl;
    const editId = editSummary.editId;

    useEffect(() => {
        if (thumbnailUrl) {
            const img = new Image();
            img.onload = () => {
                const ratio = img.width / img.height;
                if (ratio > 1.1) setAspectRatio('landscape');
                else if (ratio < 0.9) setAspectRatio('portrait');
                else setAspectRatio('square');
            };
            img.src = thumbnailUrl;
        }
    }, [thumbnailUrl]);

    const renderThumbnail = () => {
        if (thumbnailUrl) {
            return <img src={thumbnailUrl} alt={title} style={getThumbnailStyle()}/>;
        } else {
            return (
                <div style={styles.iconContainer}>
                    <AudioWaveformIcon backgroundColor="#E3E5E8"/>
                </div>
            );
        }
    };

    const getThumbnailStyle = () => {
        const baseStyle = {
            backgroundColor: '#E3E5E8',
        };

        switch (aspectRatio) {
            case 'portrait':
                return {
                    ...baseStyle,
                    position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    objectFit: 'contain',
                };
            case 'square':
                return {
                    ...baseStyle,
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                };
            case 'landscape':
            default:
                return {
                    ...baseStyle,
                    top: 0,
                    left: 0,
                    position: "absolute",
                    width: '100%',
                    height: "100%",
                    objectFit: 'cover'
                };
        }
    };

    return (
        <Box sx={{
            ...styles.cardWrapper, '&:hover .delete-button': {
                opacity: 1,
                visibility: 'visible',
            }
        }} onClick={onClick}>
            <Box style={styles.thumbnailContainer}>
                {renderThumbnail()}
                <div style={styles.draftLabel}>Draft</div>
                {editSummary.duration !== -1 && <div style={styles.durationOverlay}>{formatDuration(editSummary.duration)}</div>}
            </Box>
            <Box style={styles.infoContainer}>
                <span style={styles.title}>{formatToReadableShortTitle(title)}</span>
                <span style={styles.separator}>|</span>
                <span style={styles.date}>{formatUnixTime(createTime)}</span>
            </Box>
            <Box className="delete-button"
                 sx={{
                     position: "absolute",
                     right: 3,
                     top: 3,
                     opacity: 1,
                     visibility: 'hidden',
                     transition: 'opacity 0.2s, visibility 0.2s',
                 }}>
                <Tooltip title={"Delete draft"}>
                    <IconButton
                        onClick={async (event) => {
                            event.stopPropagation();
                            await onDelete(editId);
                        }}
                        size="medium"
                        style={{padding: '2px'}}
                    >
                        <Close style={{fontSize: "0.9rem"}}/>
                    </IconButton>
                </Tooltip>
            </Box>
        </Box>
    );
};

const styles = {
    cardWrapper: {
        width: '100%',
        cursor: 'pointer',
        transition: 'transform 0.2s ease-in-out',
        ':hover': {
            transform: 'scale(1.02)',
        },
    },
    thumbnailContainer: {
        width: '100%',
        paddingTop: '56.25%', // 16:9 aspect ratio
        position: 'relative',
        backgroundColor: '#E3E5E8',
        borderRadius: '8px',
        overflow: 'hidden',
        marginBottom: '8px',
    },
    iconContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    draftLabel: {
        position: 'absolute',
        top: '8px',
        left: '8px',
        background: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        padding: '2px 6px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: '500',
    },
    durationOverlay: {
        position: 'absolute',
        bottom: '8px',
        right: '8px',
        background: 'rgba(0, 0, 0, 0.6)',
        color: 'white',
        padding: '2px 6px',
        borderRadius: '4px',
        fontSize: '12px',
        fontWeight: '500',
    },
    infoContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '14px',
        letterSpacing: '0.16px',
    },
    separator: {
        margin: '0 6px',
        color: '#666',
    },
    date: {
        fontFamily: 'Inter',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '500',
        color: '#666',
        lineHeight: '14px',
    },
};
