import React, {useState, useContext, useEffect} from 'react';
import {
    Card,
    CardContent,
    Typography,
    Box,
    Button,
    Switch,
    Slider,
    IconButton,
    Tooltip,
    DialogContent, Select, FormControl
} from '@mui/material';
import {ClickAwayListener, Stack} from '@mui/material';
import {ArrowBackIos, Close, HourglassBottom, HourglassEmpty, HourglassFull} from '@mui/icons-material';
import MenuItem from "@mui/material/MenuItem";
import {Engine} from "@rendley/sdk";
import LoadingButton from "./LoadingButton";
import { flushSync } from 'react-dom';

const ModernMagicToolsCard = ({
                                  handleClose,
                                  textEditorRef,
                                  leftPosition,
                                  removeSilencesLoading,
                                  setRemoveSilencesLoading,
                                  removeFillerWordsLoading,
                                  setRemoveFillerWordsLoading
                              }) => {
    const [showSetPaceCard, setShowSetPaceCard] = useState(false);
    const [paceSetting, setPaceSetting] = useState(0);

    const [showEnhanceAudioCard, setShowEnhanceAudioCard] = useState(false);
    const [fillerWordTrims, setFillerWordTrims] = useState([]);
    const [fillerWordInstancesCount, setFillerWordInstancesCount] = useState(0);
    const [fillerWordsTotalDuration, setFillerWordsTotalDuration] = useState(0);

    const [silenceTrims, setSilenceTrims] = useState([]);
    const [silenceInstancesCount, setSilenceInstancesCount] = useState(0);
    const [silenceTotalDuration, setSilenceTotalDuration] = useState(0);

    const [selectedClipId, setSelectedClipId] = useState(123);

    const [recalculate, setRecalculate] = useState(false);
    const [recalculateFillerWords, setRecalculateFillerWords] = useState(false);

    const trackSettings = new Map();

    useEffect(() => {
        if (textEditorRef.current) {
            const currentTrims = textEditorRef.current.getFillerWordCuts();
            currentTrims.sort((a, b) => a.startTime - b.startTime);
            setFillerWordTrims(currentTrims);
            setFillerWordInstancesCount(currentTrims.length);
            let totalDuration = 0;
            currentTrims.forEach(trim => {
                totalDuration += trim.endTime - trim.startTime;
            });
            const readableDuration = totalDuration.toFixed(2);
            setFillerWordsTotalDuration(readableDuration);
        } else {
            console.log("Text editor ref is null");
        }
    }, [textEditorRef.current, recalculateFillerWords]);


    const maxAndTrimmedSilence = (paceSetting) => {
        if (paceSetting === 0) {
            return {maxSilence: 1024 * 1024 * 1024 * 1024 * 1024, trimmedSilence: 0};
        }
        if (paceSetting === 1) {
            return {maxSilence: 3, trimmedSilence: 1.5};
        }
        if (paceSetting === 2) {
            return {maxSilence: 1.5, trimmedSilence: 1};
        }
        if (paceSetting === 3) {
            return {maxSilence: 1, trimmedSilence: 0.5};
        }
        // if (paceSetting === 4) {
        //     return {maxSilence: 0.5, trimmedSilence: 0.3};
        // }
    }


    useEffect(() => {
        if (textEditorRef.current) {
            const {maxSilence, trimmedSilence} = maxAndTrimmedSilence(paceSetting);
            const silenceTrims = textEditorRef.current.getSilenceCuts(maxSilence, trimmedSilence);

            console.log("Silence trims is now: ", silenceTrims);

            silenceTrims.sort((a, b) => a.startTime - b.startTime);
            setSilenceTrims(silenceTrims);
            setSilenceInstancesCount(silenceTrims.length);
            let totalDuration = 0;
            silenceTrims.forEach(trim => {
                totalDuration += trim.endTime - trim.startTime;
            });
            const readableDuration = totalDuration.toFixed(2);
            setSilenceTotalDuration(readableDuration);
        } else {
            console.log("Text editor ref is null");
        }
    }, [textEditorRef.current, paceSetting, recalculate]);

    const [enhanceAudioContentType, setEnhanceAudioContentType] = useState("podcast");

    const contentTypeDescriptions = {
        podcast: "Close mic speech with some musical content.",
        conference: "Speech content where microphone is far from the speaker.",
        interview: "Speech content where microphone is close to the speaker.",
        lecture: "Speech content where microphone is far from the speaker in a large room.",
        meeting: "Speech content where microphone is close to the speaker.",
        mobile_phone: "Speech content where microphone location is variable.",
        music: "Musical content rather than speech.",
        studio: "Close mic speech content with limited background noise.",
        voice_over: "Close mic speech content."
    }

    const contentTypeTitles = {
        podcast: "Podcast",
        conference: "Conference",
        interview: "Interview",
        lecture: "Lecture",
        meeting: "Meeting",
        mobile_phone: "Mobile Phone",
        music: "Music",
        studio: "Studio",
        voice_over: "Voice Over"
    }


    const setEnhanceAudio = () => {
    };

    const paceSettingTitleMap = {
        0: "Original", 1: "Natural", 2: "Balanced", 3: "Fast", 4: "Very fast"
    }

    const handleRemoveSilencesClick = async () => {
        flushSync(() => setRemoveSilencesLoading(true));
        setTimeout(async () => {
            await handleRemoveSilences();
            setRemoveSilencesLoading(false);
            textEditorRef.current.refreshEditorState();
            setRecalculate((prev) => !prev);
        }, 0);
    }

    const handleRemoveSilences = async () => {
        const timeline = Engine.getInstance().getTimeline();

        const {maxSilence, trimmedSilence} = maxAndTrimmedSilence(paceSetting);
        const silenceTrims = textEditorRef.current.getSilenceCuts(maxSilence, trimmedSilence);

        const cutsPerClip = new Map();
        for (let trim of silenceTrims) {
            const clipId = trim.clipId;
            if (clipId) {
                if (cutsPerClip.has(clipId)) {
                    cutsPerClip.get(clipId).push(trim);
                } else {
                    cutsPerClip.set(clipId, [trim]);
                }
            }
        }

        for (let [clipId, cuts] of cutsPerClip) {
            const currentLayer = timeline.getLayerById(timeline.layersOrder[0]);
            let lastClip = timeline.getClipById(clipId);
            const clipList = [lastClip];
            for (let i = 0; i < cuts.length; i++) {
                let lastClip = clipList[clipList.length - 1];
                const badClip = await currentLayer.splitClip(lastClip.id, cuts[i].startTime);
                if (!badClip) {
                    continue;
                }
                lastClip = await currentLayer.splitClip(badClip.id, cuts[i].endTime);
                currentLayer.removeClip(badClip.id);
                clipList.push(lastClip);
            }
            for (let i = 0; i < clipList.length - 1; i++) {
                const currentClip = clipList[i];
                const nextClip = clipList[i + 1];
                nextClip.moveBy(currentClip.getRightBound() - nextClip.getLeftBound());
            }
        }
    }


    const handleFillerWordClick = async () => {
        flushSync(() => setRemoveFillerWordsLoading(true));
        setTimeout(async () => {
            await handleRemoveFillerWords();
            setRemoveFillerWordsLoading(false);
            textEditorRef.current.refreshEditorState();
            setRecalculateFillerWords((prev) => !prev);
        }, 0);
    }

    const handleRemoveFillerWords = async () => {
        const timeline = Engine.getInstance().getTimeline();

        const currentTrims = textEditorRef.current.getFillerWordCuts();

        // Keep only the first 10 trims.
        const fillerWordTrimsToUse = currentTrims.slice(0, 5);

        const cutsPerClip = new Map();
        for (let trim of fillerWordTrimsToUse) {
            const clipId = trim.clipId;
            if (clipId) {
                if (cutsPerClip.has(clipId)) {
                    cutsPerClip.get(clipId).push(trim);
                } else {
                    cutsPerClip.set(clipId, [trim]);
                }
            }
        }

        let cutCount = 0;
        let removeCount = 0;
        let moveByCount = 0;
        for (let [clipId, cuts] of cutsPerClip) {
            const currentLayer = timeline.getLayerById(timeline.layersOrder[0]);
            let lastClip = timeline.getClipById(clipId);
            const clipList = [lastClip];
            const badClipIds = [];

            for (let i = 0; i < cuts.length; i++) {
                let lastClip = clipList[clipList.length - 1];
                const badClip = await currentLayer.splitClip(lastClip.id, cuts[i].startTime);
                lastClip = await currentLayer.splitClip(badClip.id, cuts[i].endTime);
                badClipIds.push(badClip.id);
                cutCount++;
                clipList.push(lastClip);
            }

            for (let i = 0; i < badClipIds.length; i++) {
                currentLayer.removeClip(badClipIds[i]);
                removeCount++;
            }

            for (let i = 0; i < clipList.length - 1; i++) {
                const currentClip = clipList[i];
                const nextClip = clipList[i + 1];
                nextClip.moveBy(currentClip.getRightBound() - nextClip.getLeftBound());
                moveByCount++;
            }
        }
    }

    const paceSettingDescriptionMap = {
        0: "Keep all pauses in original recording.",
        1: "A smooth pace for long form content on YouTube, Spotify etc.",
        2: "An engaging pace for long form content on YouTube, Spotify etc.",
        3: "A face pace for short and long form content on YouTube, Instagram & TikTok.",
        // 4: "A very fast pace for short form content like YouTube Shorts, Reels and TikToks."
    }

    const paceSettingToTitle = (paceSetting) => paceSettingTitleMap[paceSetting];
    const paceSettingToDescription = (paceSetting) => paceSettingDescriptionMap[paceSetting];

    const setPaceIconAreaStyle = {
        display: 'flex',
        padding: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
        cursor: "not-allowed"
    }

    const magicToolsBoxStyle = {
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 9999,
        top: "70px",
        left: leftPosition,
        display: 'flex',
        width: '390px',
        padding: '16px',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'flex-start',
        borderRadius: '12px',
        background: 'var(--Basic-White, #FFF)',
        boxShadow: '0px 30px 80px 0px rgba(0, 0, 0, 0.15)',
    };

    const enhanceAudioBoxStyle = {
        cursor: 'pointer',
        position: 'absolute',
        zIndex: 9999,
        top: "70px",
        left: leftPosition,
        display: 'flex',
        width: '420px',
        height: "210px",
        padding: '16px',
        flexDirection: 'column',
        justifyContent: 'center',
        // alignItems: 'flex-start',
        borderRadius: '12px',
        background: 'var(--Basic-White, #FFF)',
        gap: "16px"
    }

    const setPaceBoxStyle = {
        display: 'flex',
        padding: '16px',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignSelf: 'stretch',
    };

    const setPaceTitleStyle = {
        display: 'flex',
        justifyContent: 'flex-start',
        gap: '8px',
        alignItems: 'center',
    }

    const setPaceTextAreaStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        width: "220px",
    };

    const setPaceDescriptionTextStyle = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignSelf: 'stretch',
        height: '52px',
        overflow: 'hidden'
    }

    const featureNameStyle = {
        color: "#1A1A1A",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "20px",
        letterSpacing: "0.14px",
        alignSelf: "flex-start"
    };

    const featureDescStyle = {
        alignSelf: "stretch",
        color: "#999",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.48px",
    };

    const constrainedFeatureDescStyle = {
        alignSelf: "stretch",
        color: "#999",
        fontFamily: "Inter",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.48px",
        maxWidth: "240px",
        height: "40px",
    }

    const smallDescStyle = {
        alignSelf: "stretch",
        color: "#999",
        fontFamily: "Inter",
        fontSize: "9px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "16px",
        letterSpacing: "0.48px",
    };

    const dropdownStyle = {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }

    const BackArrow = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                 onClick={() => setShowSetPaceCard(false)}>
                <path d="M15 17L10 12L15 7" stroke="#1A1A1A" strokeWidth="1.5" strokeLinecap="square"
                      strokeLinejoin="round"/>
            </svg>
        );
    };

    const toolTipTitleForPaceSetting = (paceSetting) => {
        if (paceSetting === 0) {
            return "Keep all pauses"
        }
        if (paceSetting === 1) {
            return "3s or more"
        }
        if (paceSetting === 2) {
            return "1.5s or more"
        }
        if (paceSetting === 3) {
            return "1s or more"
        }
        // if (paceSetting === 4) {
        //     return "0.5s or more"
        // }
    }

    const ValueLabelComponent = (props) => {
        const {children, open, value} = props;
        const tooltipMessage = toolTipTitleForPaceSetting(value)

        return (
            <Tooltip open={open} enterTouchDelay={0} placement="top" title={tooltipMessage}>
                {children}
            </Tooltip>
        );
    }

    const handleSliderChange = (event, newValue) => {
        setPaceSetting(newValue);
    };

    const marks = [
        {value: 0, label: ''},
        {value: 1, label: ''},
        {value: 2, label: ''},
        {value: 3, label: ''},
        // {value: 4, label: ''},
    ];

    if (showEnhanceAudioCard) {
        return (
            <ClickAwayListener onClickAway={handleClose}>
                <Box sx={enhanceAudioBoxStyle}>
                    <Box sx={setPaceTitleStyle}>
                        Enhance Audio
                    </Box>
                    <Box sx={featureNameStyle}>
                        Select the category to apply the best audio enhancements to the clip.
                    </Box>
                    <Box sx={{display: "flex", flexDirection: "row", justifyContent: "space-between", gap: "30px"}}>
                        <Box sx={{display: "flex", flexDirection: "column", gap: "8px"}}>
                            <Box>
                                <FormControl sx={{width: "200px"}}>
                                    <Select
                                        sx={{width: "200px", height: "40px", zIndex: 999}}
                                        label={contentTypeTitles[enhanceAudioContentType]}
                                        variant="outlined"
                                        value={enhanceAudioContentType}
                                        onChange={(e) => setEnhanceAudioContentType(e.target.value)}
                                    >
                                        {Object.entries(contentTypeTitles).map(([key, title]) => (
                                            <MenuItem key={key} value={key}>
                                                {title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                            <Box sx={constrainedFeatureDescStyle}>
                                {contentTypeDescriptions[enhanceAudioContentType]}
                            </Box>
                        </Box>
                        <Box>
                            <Button variant={"contained"} sx={{color: "#2b6bfd", height: "40px"}}>
                                <Typography sx={{color: "white"}}>Apply</Typography>
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </ClickAwayListener>
        )
    }

    if (showSetPaceCard) {
        return (
            <ClickAwayListener onClickAway={handleClose}>
                <Box sx={magicToolsBoxStyle}>
                    <Box sx={setPaceTitleStyle}>
                        <BackArrow/>
                        <Box sx={featureNameStyle}>
                            Set Pace
                        </Box>
                    </Box>
                    <Box sx={setPaceBoxStyle}>
                        <Box sx={setPaceDescriptionTextStyle}>
                            <Box sx={featureNameStyle}>
                                {paceSettingToTitle(paceSetting)}
                            </Box>
                            <Box sx={featureDescStyle}>
                                {paceSettingToDescription(paceSetting)}
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{width: '100%', padding: '0 16px'}}>
                        <Slider
                            value={paceSetting}
                            onChange={handleSliderChange}
                            marks={marks}
                            step={1}
                            min={0}
                            max={3}
                            valueLabelFormat={(value) => toolTipTitleForPaceSetting(value)}
                            valueLabelDisplay={'auto'}
                            sx={{
                                '& .MuiSlider-mark': {
                                    backgroundColor: '#2b62bd',
                                    height: 3,
                                    width: 3,
                                    borderRadius: '50%',
                                },
                                '& .MuiSlider-rail': {color: '#E8E8E8'},
                                '& .MuiSlider-track': {color: '#2B6BFD'},
                                '& .MuiSlider-thumb': {color: '#2B6BFD'},
                            }}
                        />
                    </Box>
                    <Box sx={{...setPaceBoxStyle, justifyContent: 'space-between', width: '100%'}}>
                        <Typography sx={featureDescStyle}>Original</Typography>
                        <Typography sx={featureDescStyle}>Very fast</Typography>
                    </Box>
                    <Box
                        sx={{...setPaceBoxStyle, justifyContent: 'space-between', width: '100%', alignItems: "center"}}>
                        <Box>
                            <Typography sx={featureDescStyle}>Remove pauses.</Typography>
                            {silenceInstancesCount > 0 ? <Box
                                    sx={featureDescStyle}>Remove {silenceInstancesCount} pauses
                                    ({silenceTotalDuration}s)</Box> :
                                <Box sx={featureDescStyle}>No pauses to remove.</Box>}
                        </Box>
                        <Box>
                            <LoadingButton disabled={!silenceInstancesCount || removeSilencesLoading}
                                           isLoading={removeSilencesLoading} onClick={handleRemoveSilencesClick}/>
                        </Box>
                    </Box>
                </Box>
            </ClickAwayListener>
        );
    }

    const ForwardArrow = () => {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M10 17L15 12L10 7" stroke="#666666" stroke-width="1.5" stroke-linecap="square"
                      stroke-linejoin="round"/>
            </svg>
        )
    }

    const handleShowSetPaceCardClick = () => {
        // setShowSetPaceCard(true);
    }

    return (
        <>
            <ClickAwayListener onClickAway={handleClose}>
                <Box sx={magicToolsBoxStyle}>
                    <Box sx={setPaceBoxStyle}>
                        <Box sx={setPaceTextAreaStyle}>
                            <Box sx={featureNameStyle}>
                                Set Pace
                            </Box>
                            <Box sx={featureDescStyle}>
                                Remove pauses and improve speech flow.
                            </Box>
                        </Box>
                        <Box sx={setPaceIconAreaStyle} onClick={handleShowSetPaceCardClick}>
                            <ForwardArrow/>
                        </Box>
                    </Box>
                    <Box sx={setPaceBoxStyle}>
                        <Box sx={setPaceTextAreaStyle}>
                            <Box sx={featureNameStyle}>
                                Smooth Speech
                            </Box>
                            <Box sx={featureDescStyle}>
                                Remove filler words.
                                {fillerWordInstancesCount > 0 ? <Box
                                        sx={featureDescStyle}>{fillerWordInstancesCount} found, {fillerWordsTotalDuration}s
                                        total.</Box> :
                                    <Box sx={featureDescStyle}>No filler words found.</Box>}
                            </Box>
                        </Box>
                        <Box sx={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                            <LoadingButton disabled={!fillerWordInstancesCount || removeFillerWordsLoading}
                                           onClick={handleFillerWordClick} isLoading={removeFillerWordsLoading}/>
                        </Box>
                    </Box>
                </Box>
            </ClickAwayListener>
        </>
    )
}

export default ModernMagicToolsCard;