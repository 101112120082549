import React from 'react';
import instagram from "./svgs/instagram.svg"
import linkedin from './svgs/linkedin.svg';
import spotify from './svgs/spotify.svg';
import x from './svgs/x.svg';
import youtube from './svgs/youtube.svg';
import tiktok from './svgs/tiktok.svg';
import applePodcasts from './svgs/applePodcasts.svg';
import shorts from './svgs/shorts.svg';

export const InstagramIcon = () => {
    return (
        <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
            <img src={instagram} alt="G icon" width="64" height="64"/>
        </a>
    );
};

export const LinkedInIcon = () => {
    return (
        <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
            <img src={linkedin} alt="G icon" width="64" height="64"/>
        </a>
    );
};

export const SpotifyIcon = () => {
    return (
        <a href="https://spotify.com" target="_blank" rel="noopener noreferrer">
            <img src={spotify} alt="G icon" width="64" height="64"/>
        </a>
    );
};

export const XIcon = () => {
    return (
        <a href="https://x.com" target="_blank" rel="noopener noreferrer">
            <img src={x} alt="G icon" width="64" height="64"/>
        </a>
    );
};

export const YouTubeIcon = () => {
    return (
        <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
            <img src={youtube} alt="G icon" width="64" height="64"/>
        </a>
    );
};

export const SmallYouTubeIcon = () => {
    return (
        <a href="https://youtube.com/@librettofm" target="_blank" rel="noopener noreferrer">
            <img src={youtube} alt="G icon" width="24" height="20"/>
        </a>
    );
};

export const TikTokIcon = () => {
    return (
        <a href="https://tiktok.com" target="_blank" rel="noopener noreferrer">
            <img src={tiktok} alt="G icon" width="64" height="64"/>
        </a>
    );
};

export const ApplePodcastsIcon = () => {
    return (
        <a href="https://podcasts.apple.com" target="_blank" rel="noopener noreferrer">
            <img src={applePodcasts} alt="G icon" width="64" height="64"/>
        </a>
    );
};

export const ShortsIcon = () => {
    return (
        <a href="https://youtube.com/shorts" target="_blank" rel="noopener noreferrer">
            <img src={shorts} alt="G icon" width="64" height="64"/>
        </a>
    );
};
