import React, {useEffect, useState} from 'react';
import StatusChip from "../../components/StatusChip";
import Box from "@mui/material/Box";
import {formatToReadableLargeTitle, formatUnixTime} from "../../utils/utils";

export const AudioWaveformIcon = (backgroundColor) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="124" height="93" viewBox="0 0 124 93" fill="none">
            {/* Audio waveform SVG code */}
            <rect width="124" height="93" rx="6" fill={backgroundColor}/>
            <line x1="69.5" y1="36.333" x2="69.5" y2="57.6663" stroke="#2B6BFD" stroke-width="3"/>
            <line x1="87.5" y1="44.7139" x2="87.5" y2="49.2853" stroke="#2B6BFD" stroke-width="3"/>
            <line x1="57.5" y1="41.667" x2="57.5" y2="52.3337" stroke="#2B6BFD" stroke-width="3"/>
            <line x1="81.5" y1="40.1426" x2="81.5" y2="53.8569" stroke="#2B6BFD" stroke-width="3"/>
            <line x1="51.5" y1="35.5713" x2="51.5" y2="58.4284" stroke="#2B6BFD" stroke-width="3"/>
            <line x1="63.5" y1="40.1426" x2="63.5" y2="53.8569" stroke="#2B6BFD" stroke-width="3"/>
            <line x1="45.5" y1="43.1904" x2="45.5" y2="50.8095" stroke="#2B6BFD" stroke-width="3"/>
            <line x1="75.5" y1="31" x2="75.5" y2="63" stroke="#2B6BFD" stroke-width="3"/>
            <line x1="39.5" y1="44.7139" x2="39.5" y2="49.2853" stroke="#2B6BFD" stroke-width="3"/>
        </svg>
    )
}

export const VideoCameraIcon = ({ backgroundColor }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="124" height="93" viewBox="0 0 124 93" fill="none">
            <rect width="124" height="93" rx="6" fill={backgroundColor}/>
            <path d="M35 31H70C72.7614 31 75 33.2386 75 36V57C75 59.7614 72.7614 62 70 62H35C32.2386 62 30 59.7614 30 57V36C30 33.2386 32.2386 31 35 31Z" stroke="#2B6BFD" strokeWidth="3"/>
            <path d="M75 42L94 33V60L75 51" stroke="#2B6BFD" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
            <circle cx="52.5" cy="46.5" r="7.5" stroke="#2B6BFD" strokeWidth="3"/>
            <path d="M40 57C40 52.5817 43.5817 49 48 49H57C61.4183 49 65 52.5817 65 57" stroke="#2B6BFD" strokeWidth="3"/>
        </svg>
    );
};

export const ListViewThumbnailCard = ({isVideo, thumbnailUrl, onClick, status, title}) => {
    const [aspectRatio, setAspectRatio] = useState('16:9');
    const [isImageLoaded, setIsImageLoaded] = useState(true);

    useEffect(() => {
        if (isVideo && thumbnailUrl) {
            const img = new Image();
            img.onload = () => {
                const ratio = img.width / img.height;
                if (ratio > 1.1) setAspectRatio('landscape');
                else if (ratio < 0.9) setAspectRatio('portrait');
                else setAspectRatio('square');
            };
            img.src = thumbnailUrl;
        }
    }, [isVideo, thumbnailUrl]);

    const renderThumbnail = () => {
        if (status === 'Ready') {
            if (isVideo && thumbnailUrl && isImageLoaded) {
                return <img src={thumbnailUrl} alt={title} style={getThumbnailStyle()} onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = setIsImageLoaded(false);
                }}/>;
            } else if (isVideo) {
                return (
                    <div style={styles.iconContainer}>
                        <VideoCameraIcon backgroundColor="#E3E5E8"/>
                    </div>
                );
            } else {
                return (
                    <div style={styles.iconContainer}>
                        <AudioWaveformIcon backgroundColor="#E3E5E8"/>
                    </div>
                );
            }
        } else if (status === 'Processing') {
            return (
                <div style={styles.iconContainer}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
                        <path opacity="0.3" d="M2 5.5L6 9.5L10 5.5V2H2V5.5Z" fill="#F2880C"/>
                        <path opacity="0.3" d="M2 5.5L6 9.5L10 5.5V2H2V5.5Z" fill="#F2880C"/>
                        <path
                            d="M12 0H0V6L4 10L0.0100002 14.01L0 20H12L11.99 14.01L8 10L12 6.01V0ZM10 14.5V18H2V14.5L6 10.5L10 14.5ZM10 5.5L6 9.5L2 5.5V2H10V5.5Z"
                            fill="#F2880C"/>
                    </svg>
                </div>
            );
        } else if (status === 'Uploading') {
            return (
                <div style={styles.iconContainer}>
                    {/* You can add an upload icon SVG here */}
                    <span>Uploading...</span>
                </div>
            );
        }
    };

    const getThumbnailStyle = () => {
        const baseStyle = {
            backgroundColor: '#E3E5E8', // Light background for non-covered areas
        };

        switch (aspectRatio) {
            case 'portrait':
                return {
                    ...baseStyle, position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    objectFit: 'contain',
                };
            case 'square':
                return {...baseStyle, position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                };
            case 'landscape':
            default:
                return {
                    ...baseStyle,
                    top: 0,
                    left: 0,
                    position: "absolute",
                    width: '100%',
                    height: "100%",
                    objectFit: 'cover'
                };
        }
    };

    const hoverOverlay = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        opacity: 0,
        transition: 'opacity 0.2s ease-in-out',
        ':hover': {
            opacity: 1,
        },
    }

    return (
        <Box sx={styles.cardWrapper} onClick={onClick}>
            <Box style={styles.thumbnailContainer}>
                {renderThumbnail()}
                <div style={hoverOverlay}/>
            </Box>
        </Box>
    );
}

const ProjectThumbnailCard = ({title, createTime, status, progress, isVideo, thumbnailUrl, onClick}) => {
    const [aspectRatio, setAspectRatio] = useState('16:9');

    useEffect(() => {
        if (isVideo && thumbnailUrl) {
            const img = new Image();
            img.onload = () => {
                const ratio = img.width / img.height;
                if (ratio > 1.1) setAspectRatio('landscape');
                else if (ratio < 0.9) setAspectRatio('portrait');
                else setAspectRatio('square');
            };
            img.src = thumbnailUrl;
        }
    }, [isVideo, thumbnailUrl]);

    if (status === "Processing...") {
        status = "Processing";
    }

    const renderThumbnail = () => {
        if (status === 'Ready') {
            if (isVideo && thumbnailUrl) {
                return <img src={thumbnailUrl} alt={title} style={getThumbnailStyle()}/>;
            } else if (isVideo) {
                return (
                    <div style={styles.iconContainer}>
                        <VideoCameraIcon backgroundColor="#E3E5E8"/>
                    </div>
                );
            } else {
                return (
                    <div style={styles.iconContainer}>
                        <AudioWaveformIcon backgroundColor="#E3E5E8"/>
                    </div>
                );
            }
        } else if (status === 'Processing') {
            return (
                <div style={styles.iconContainer}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="20" viewBox="0 0 12 20" fill="none">
                        <path opacity="0.3" d="M2 5.5L6 9.5L10 5.5V2H2V5.5Z" fill="#F2880C"/>
                        <path opacity="0.3" d="M2 5.5L6 9.5L10 5.5V2H2V5.5Z" fill="#F2880C"/>
                        <path
                            d="M12 0H0V6L4 10L0.0100002 14.01L0 20H12L11.99 14.01L8 10L12 6.01V0ZM10 14.5V18H2V14.5L6 10.5L10 14.5ZM10 5.5L6 9.5L2 5.5V2H10V5.5Z"
                            fill="#F2880C"/>
                    </svg>
                </div>
            );
        } else if (status === 'Uploading') {
            return (
                <div style={styles.iconContainer}>
                    {/* You can add an upload icon SVG here */}
                    <span>Uploading...</span>
                </div>
            );
        }
    };

    const getThumbnailStyle = () => {
        const baseStyle = {
            backgroundColor: '#E3E5E8', // Light background for non-covered areas
        };

        switch (aspectRatio) {
            case 'portrait':
                return {
                    ...baseStyle, position: 'absolute',
                    top: 0,
                    left: '50%',
                    transform: 'translateX(-50%)',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    objectFit: 'contain',
                };
            case 'square':
                return {...baseStyle, position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    height: '100%',
                    width: 'auto',
                    maxWidth: '100%',
                    maxHeight: '100%',
                    objectFit: 'contain',
                };
            case 'landscape':
            default:
                return {
                    ...baseStyle,
                    top: 0,
                    left: 0,
                    position: "absolute",
                    width: '100%',
                    height: "100%",
                    objectFit: 'cover'
                };
        }
    };

    const hoverOverlay = {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.1)',
        opacity: 0,
        transition: 'opacity 0.2s ease-in-out',
        ':hover': {
            opacity: 1,
        },
    }

    return (
        <Box sx={styles.cardWrapper} onClick={onClick}>
            <Box style={styles.thumbnailContainer}>
                {renderThumbnail()}
                <div style={hoverOverlay}/>
            </Box>
            <Box style={styles.infoContainer}>
                <Box style={styles.titleContainer}>
                    <Box style={styles.title}>{formatToReadableLargeTitle(title)}</Box>
                    <span style={styles.date}>{formatUnixTime(createTime)}</span>
                </Box>
                <StatusChip status={status} progress={progress}/>
            </Box>
        </Box>
    );
};

const styles = {
    cardWrapper: {
        width: '100%',
        cursor: 'pointer',
        transition: 'transform 0.2s ease-in-out',
        ':hover': {
            transform: 'scale(1.02)',
        },
    },
    thumbnailContainer: {
        width: '100%',
        paddingTop: '56.25%', // 16:9 aspect ratio
        position: 'relative',
        backgroundColor: '#E3E5E8',
        borderRadius: '8px',
        overflow: 'hidden',
        marginBottom: '8px',
    },
    thumbnailImage: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        objectFit: 'cover',
    },
    iconContainer: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    infoContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
    },
    titleContainer: {
        flex: 1,
        marginRight: '8px',
    },
    title: {
        fontFamily: 'Inter',
        fontSize: '13px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '14px',
        letterSpacing: '0.16px',
    },
    date: {
        fontSize: '12px',
        color: '#666',
    },
};

export default ProjectThumbnailCard;
