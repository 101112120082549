// Define your TypeScript type

import {RendleyService} from "../../editor/services/RendleyService";
import {RendleyStore} from "../../editor/store/RendleyStore";
import {
    AudioClipOptions,
    Clip,
    ClipTypeEnum,
    Engine,
    ImageClipOptions,
    TextClipOptions,
    VideoClipOptions
} from "@rendley/sdk";
import {ClipsToTimelineItems, GetSRTSubtitleUrl} from "./MultitrackTranscript";
import {IsVideoTrackType} from "../../utils/utils";

export const deserializeInitialState = async ({track}) => {

    const objectUrl = IsVideoTrackType(track.trackType) ? track.renditionUrl : track.objectUrl;
    const audioExtension = !IsVideoTrackType(track.trackType) ? track.objectName.split('.').pop() : null;
    const mimeType = IsVideoTrackType(track.trackType) ? "video/mp4" : `audio/${audioExtension}`;

    // Parallelize the fetching of the object and the transcript
    const fetchObject = fetch(objectUrl).then((response) => response.blob());
    const fetchTranscript = (track.transcriptUrl != null && track.hasTranscript && !track.isSilentAudio) ?
        fetch(track.transcriptUrl).then((response) => response.json()).catch(() => {
            return null;
        }) : null;

    const [blob, transcript] = await Promise.all([fetchObject, fetchTranscript]);

    const file = new File([blob], track.title, {type: mimeType});

    // Add media to gallery
    const mediaDataId = await RendleyService.addMediaToGallery(file, mimeType);
    if (mediaDataId == null) return;
    RendleyService.getMediaById(mediaDataId)?.setPermanentUrl(objectUrl);
    RendleyService.getMediaById(mediaDataId)?.setCustomData("entityId", track.trackId);
    RendleyService.getMediaById(mediaDataId)?.setCustomData("transcriptUrl", track.transcriptUrl);
    RendleyService.getMediaById(mediaDataId)?.setCustomData("transcript", transcript);

    window.dispatchEvent(new CustomEvent("LIBRETTO_LIBRARY_ADDED", {
        detail: {
            filename: track.title,
            id: mediaDataId,
            permanentUrl: objectUrl,
            mimeType: mimeType,
            entityId: track.trackId,
            transcriptUrl: track.hasTranscript ? track.transcriptUrl : "",
        }
    }));

    const layer = RendleyService.createLayer();
    const clip = await RendleyService.addMediaToLayer(layer.id, mediaDataId, 0);
    if (clip == null) {
        return
    }
    RendleyService.getClipById(clip.id)?.setCustomData("layerId", layer.id);
}

// Parse and apply the editor state to the current editor instance.
export const deserialize = async ({display, library, timeline, corrections}) => {

    if (display) {
        Engine.getInstance().getDisplay().setResolution(Number(display.width), Number(display.height));
        Engine.getInstance().getDisplay().setBackgroundColor(display.backgroundColor);
    }

    const oldToNewMediaDataIdMap = new Map<string, string>();
    const mediaIdToFilenameMap = new Map<string, string>();

    if (library && library.media) {
        const mediaPromises = library.media.map(async (media) => {

            const {filename, permanentUrl, mimeType, type, id} = media;

            let customDataObject, transcriptUrl, entityId, transcript;

            if (type === ClipTypeEnum.VIDEO || type === ClipTypeEnum.AUDIO) {
                 customDataObject = Object.fromEntries(media.customData);
                 transcriptUrl = customDataObject.transcriptUrl;
                 entityId = customDataObject.entityId;
                 transcript = customDataObject.transcript;
            }

            if (!permanentUrl) return;

            const fetchObject = fetch(permanentUrl).then((response) => response.blob());
            const [blob] = await Promise.all([fetchObject]);

            const file = new File([blob], filename, {type: mimeType});

            let createdMediaDataId = null;

            try {
                createdMediaDataId = await Engine.getInstance().getLibrary().addMedia(file, mimeType);
            } catch (error) {
                console.error("Error creating media data: ", error);
                // Reload the page if there is an error creating the media data
                window.location.reload();
            }

            if (createdMediaDataId == null) return;

            oldToNewMediaDataIdMap.set(id, createdMediaDataId);
            mediaIdToFilenameMap.set(createdMediaDataId, filename);

            const mediaObj = RendleyService.getMediaById(createdMediaDataId);
            if (mediaObj) {
                mediaObj.setPermanentUrl(permanentUrl);
                mediaObj.setCustomData("entityId", entityId);
                mediaObj.setCustomData("transcriptUrl", transcriptUrl);
                mediaObj.setCustomData("transcript", transcript);
            }
        });

        await Promise.all(mediaPromises);
    }

    if (timeline && timeline.layers) {

        const layers = timeline.layers;

        for (const layerIndex in layers) {

            if (!layers.hasOwnProperty(layerIndex)) continue;

            const layer = layers[layerIndex];

            if (!layer) continue;

            const createdLayer = RendleyService.createLayer();

            const clips = layer.clips;

            for (const clipIndex in clips) {

                if (!clips.hasOwnProperty(clipIndex)) continue;

                const clip = clips[clipIndex];

                if (!clip) continue;

                const mediaDataId = clip.mediaDataId;

                const newMediaDataId = oldToNewMediaDataIdMap.get(mediaDataId);

                if ([ClipTypeEnum.VIDEO, ClipTypeEnum.AUDIO, ClipTypeEnum.IMAGE].includes(clip.type) && !newMediaDataId) continue;

                let createdClip: Clip | null = null;

                if (clip.type === ClipTypeEnum.VIDEO) {

                    const clipOptions: VideoClipOptions = {
                        type: clip.type,
                        mediaDataId: newMediaDataId,
                        startTime: clip.startTime,
                        duration: clip.duration,
                    }

                    createdClip = await createdLayer.addClip(clipOptions);
                }

                if (clip.type === ClipTypeEnum.AUDIO) {
                    const clipOptions: AudioClipOptions = {
                        type: clip.type,
                        mediaDataId: newMediaDataId,
                        startTime: clip.startTime,
                        duration: clip.duration,
                    }

                    createdClip = await createdLayer.addClip(clipOptions);
                }

                if (clip.type === ClipTypeEnum.IMAGE) {
                    const clipOptions: ImageClipOptions = {
                        type: clip.type,
                        mediaDataId: newMediaDataId,
                        startTime: clip.startTime,
                        duration: clip.duration,
                    }

                    createdClip = await createdLayer.addClip(clipOptions);
                }

                if (clip.type === ClipTypeEnum.TEXT) {
                    const clipOptions: TextClipOptions = {
                        type: clip.type,
                        text: clip.text,
                        startTime: clip.startTime,
                        duration: clip.duration,
                    }

                    createdClip = await createdLayer.addClip(clipOptions);
                }

                if (createdClip) {

                    Engine.getInstance().getTimeline().getClipById(createdClip.id)?.setCustomData("layerId", createdLayer.id);
                    Engine.getInstance().getTimeline().getClipById(createdClip.id)?.setCustomData("entityId", clip.entityId);
                    Engine.getInstance().getTimeline().getClipById(createdClip.id)?.setStartTime(clip.startTime);
                    Engine.getInstance().getTimeline().getClipById(createdClip.id)?.setLeftTrim(clip.leftTrim);
                    Engine.getInstance().getTimeline().getClipById(createdClip.id)?.setRightTrim(clip.rightTrim);
                    const filename = mediaIdToFilenameMap.get(newMediaDataId);
                    RendleyStore.setFilenameByClipId(createdClip.id, filename);
                }
            }

            // Process subtitles clips last.
            for (const clipIndex in clips) {

                if (!clips.hasOwnProperty(clipIndex)) continue;

                const clip = clips[clipIndex];

                if (!clip || clip.type != ClipTypeEnum.SUBTITLES) continue;

                const timelineLayers = ClipsToTimelineItems(Engine.getInstance().getTimeline().getClips());
                const srtContent = GetSRTSubtitleUrl({timelineStateLayers: timelineLayers, corrections: corrections});

                const subtitlesClipId = await RendleyService.importSubtitlesSrt(srtContent);
                RendleyStore.setSubtitlesClipId(subtitlesClipId);

                Engine.getInstance().getTimeline().getClipById(subtitlesClipId)?.setStartTime(clip.startTime);
                Engine.getInstance().getTimeline().getClipById(subtitlesClipId)?.setLeftTrim(clip.leftTrim);
                Engine.getInstance().getTimeline().getClipById(subtitlesClipId)?.setRightTrim(clip.rightTrim);
            }
        }
    }
}