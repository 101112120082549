import React, {useState} from 'react';
import {
    Box,
    TextField,
    MenuItem,
    Button,
    ToggleButton,
    ToggleButtonGroup,
    Switch,
    Typography,
    Card,
    CardContent, Stack, FormControl, Select, InputLabel
} from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import {ToggleButton as LibrettoToggleButton} from "../NewStudio/ToggleButton";
import Tooltip from "@mui/material/Tooltip";

const headerStyle = {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '500',
    lineHeight: '20px',
    letterSpacing: '0.14px',
};

const buttonStyle = {
    display: "flex",
    height: "48px",
    padding: "10px 20px",
    justifyContent: "center",
    alignItems: "center",
    gap: "10px",
    color: "white",
    borderRadius: "8px",
    minWidth: "100px",
    cursor: "pointer",
    background: "#2B6BFD",
    "&:hover": {
        background: "linear-gradient(0deg, rgba(0, 0, 0, 0.15) 0%, rgba(0, 0, 0, 0.15) 100%), #2B6BFD",
    },
    "&:focus": {
        background: "linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #2B6BFD"
    },
    "&:disabled": {
        background: "#2B6BFD",
        opacity: 0.3,
        cursor: "not-allowed",
    }
};

const ModernExportOptionsCard = ({handleClose, editTitle, handleExportRequest, isRestrictedPlan}) => {
    const [tab, setTab] = useState('video');
    const [videoQuality, setVideoQuality] = useState('720p');
    const [audioQuality, setAudioQuality] = useState('mp3');
    const [enhanceAudio, setEnhanceAudio] = useState(false);

    const [enhanceAudioContentType, setEnhanceAudioContentType] = useState('podcast');

    const handleTabChange = (event, newTab) => {
        if (newTab !== null) setTab(newTab);
    };

    const contentTypeDescriptions = {
        podcast: "Close mic speech with some musical content.",
        conference: "Speech content where microphone is far from the speaker.",
        interview: "Speech content where microphone is close to the speaker.",
        lecture: "Speech content where microphone is far from the speaker in a large room.",
        meeting: "Speech content where microphone is close to the speaker.",
        mobile_phone: "Speech content where microphone location is variable.",
        music: "Musical content rather than speech.",
        studio: "Close mic speech content with limited background noise.",
        voice_over: "Close mic speech content."
    }

    const contentTypeTitles = {
        podcast: "Podcast",
        conference: "Conference",
        interview: "Interview",
        lecture: "Lecture",
        meeting: "Meeting",
        mobile_phone: "Mobile Phone",
        music: "Music",
        studio: "Studio",
        voice_over: "Voice Over"
    }

    const handleClickedAway = (event) => {
        const cardRect = {
            top: 70,
            right: 32,
            width: 300,
            height: 340
        };

        // Calculate the left boundary of the card
        const cardLeft = window.innerWidth - cardRect.right - cardRect.width;
        const cardBottom = cardRect.top + cardRect.height;

        // Get the click coordinates
        const {clientX, clientY} = event;

        // Check if the click is inside the card's bounding box
        if (clientX >= cardLeft && clientX <= (cardLeft + cardRect.width) &&
            clientY >= cardRect.top && clientY <= cardBottom) {
            // If the click is inside the card, do not close it
            return;
        }
        handleClose();
    };

    const mainStyle = {
        position: "absolute",
        top: "70px",
        right: "32px",
        display: "flex",
        width: "300px",
        height: "auto",
        padding: "16px",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
        gap: "12px",
        borderRadius: "12px",
        background: "#FFF",
        boxShadow: "0px 30px 80px 0px rgba(0, 0, 0, 0.15)",
        zIndex: 1000,
    };

    const audioRSTitleStyle = {
        fontFamily: "Inter",
        fontSize: "17px",
        fontStyle: "normal",
        fontWeight: "450",
        lineHeight: "20px",
        letterSpacing: "0.16px",
    }

    return (
        <ClickAwayListener onClickAway={handleClickedAway}>
            <Box
                sx={mainStyle}
            >
                <Box style={headerStyle}>Export Options</Box>

                <ToggleButtonGroup
                    value={tab}
                    exclusive
                    onChange={handleTabChange}
                    style={{marginTop: '10px', marginBottom: '20px'}}
                    fullWidth
                >
                    <ToggleButton value="video" style={{textTransform: 'none'}}>
                        Video
                    </ToggleButton>
                    <ToggleButton value="audio" style={{textTransform: 'none'}}>
                        Audio
                    </ToggleButton>
                </ToggleButtonGroup>

                {tab === 'video' ? (
                    <>
                        <TextField
                            select
                            label="Video Quality"
                            value={videoQuality}
                            onChange={(e) => setVideoQuality(e.target.value)}
                            fullWidth
                            variant="outlined"
                            sx={{marginBottom: 2}}
                        >
                            <MenuItem value="720p">720p</MenuItem>
                            <MenuItem value="1080p">1080p</MenuItem>
                        </TextField>

                        <Box sx={{
                            flexDirection: "row",
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <Box sx={audioRSTitleStyle}>
                                Apply audio enhancement
                            </Box>
                            <Tooltip title={isRestrictedPlan ? "Unlock on Standard Plan" : ""} placement={"top"}>
                                <Box>
                                    <LibrettoToggleButton checked={enhanceAudio} disabled={isRestrictedPlan}
                                                          onChange={(e) => setEnhanceAudio(!enhanceAudio)}/>
                                </Box>
                            </Tooltip>
                        </Box>
                        {enhanceAudio && <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: "10px"}}>
                            <Box sx={{fontSize: "13px", color: "#1a1a1a", fontWeight: "550"}}>
                                Content Type
                            </Box>
                            <Box>
                                <FormControl sx={{width: "180px"}}>
                                    <Select
                                        sx={{width: "180px", height: "40px", zIndex: 999}}
                                        variant="outlined"
                                        title={"Content"}
                                        value={enhanceAudioContentType}
                                        onChange={(e) => setEnhanceAudioContentType(e.target.value)}
                                    >
                                        {Object.entries(contentTypeTitles).map(([key, title]) => (
                                            <MenuItem key={key} value={key}>
                                                {title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>}
                        {enhanceAudio && <Box sx={{fontSize: "14px", color: "#666", marginTop: "5px", height: "24px"}}>
                            {contentTypeDescriptions[enhanceAudioContentType]}
                        </Box>}
                        <Button
                            variant="contained"
                            fullWidth
                            sx={buttonStyle}
                            style={{marginTop: '20px'}}
                            onClick={() => handleExportRequest({kind: "video", quality: videoQuality, enhance: enhanceAudio, contentType: enhanceAudioContentType})}
                        >
                            Export Video
                        </Button>
                    </>
                ) : (
                    <>
                        <TextField
                            select
                            label="Audio Quality"
                            value={audioQuality}
                            onChange={(e) => setAudioQuality(e.target.value)}
                            fullWidth
                            variant="outlined"
                            sx={{marginBottom: 2}}
                        >
                            <MenuItem value="mp3">MP3</MenuItem>
                            <MenuItem value="wav">WAV</MenuItem>
                        </TextField>

                        <Box sx={{
                            flexDirection: "row",
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <Box sx={audioRSTitleStyle}>
                                Apply audio enhancement
                            </Box>
                            <Box>
                                <LibrettoToggleButton checked={enhanceAudio}
                                                      onChange={(e) => setEnhanceAudio(!enhanceAudio)}/>
                            </Box>
                        </Box>

                        {enhanceAudio && <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", gap: "10px"}}>
                            <Box sx={{fontSize: "13px", color: "#1a1a1a", fontWeight: "550"}}>
                                Content Type
                            </Box>
                            <Box>
                                <FormControl sx={{width: "180px"}}>
                                    <Select
                                        sx={{width: "180px", height: "40px", zIndex: 999}}
                                        variant="outlined"
                                        title={"Content"}
                                        value={enhanceAudioContentType}
                                        onChange={(e) => setEnhanceAudioContentType(e.target.value)}
                                    >
                                        {Object.entries(contentTypeTitles).map(([key, title]) => (
                                            <MenuItem key={key} value={key}>
                                                {title}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>}
                        {enhanceAudio && <Box sx={{fontSize: "14px", color: "#666", marginTop: "5px", height: "24px"}}>
                            {contentTypeDescriptions[enhanceAudioContentType]}
                        </Box>}

                        <Button
                            variant="contained"
                            fullWidth
                            sx={buttonStyle}
                            style={{marginTop: '20px'}}
                            onClick={() => handleExportRequest({kind: "audio", quality: audioQuality, enhance: enhanceAudio, contentType: enhanceAudioContentType})}
                        >
                            Export Audio
                        </Button>
                    </>
                )}
            </Box>
        </ClickAwayListener>
    );
};

export default ModernExportOptionsCard;
