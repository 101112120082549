import React, { useState, useEffect, Fragment } from "react";
import debounce from "lodash.debounce";
import {StockMediaService} from "../../../../services/StockMediaService"; // Adjust import as needed
import {RendleyService} from "../../../../services/RendleyService"; // Adjust import as needed
import {RendleyStore} from "../../../../store/RendleyStore"; // Adjust import as needed
import * as PexelsTypes from "../../../../types/pexels.types";
import * as GiphyTypes from "../../../../types/giphy.types";
import { SearchIcon } from "../../../../assets/icons/SearchIcon";
import { ConfigError } from "../../../../assets/icons/ConfigErrorIcon";
import InfoBanner from "../../../../components/InfoBanner/InfoBanner";
import Searchbar from "../../../../components/Searchbar/Searchbar";
import PanelGridListSection from "../../../../components/PanelGridListSection/PanelGridListSection";
import EffectShowcaseCard from "../../../../components/EffectsShowcaseCard/EffectShowcaseCard"; // Adjust imports as needed

const StockPanelContainer = ({ onClose }) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);

    const [images, setImages] = useState<PexelsTypes.Photo[]>([]);
    const [videos, setVideos] = useState<PexelsTypes.Video[]>([]);
    const [gifs, setGifs] = useState<GiphyTypes.Gif[]>([]);
    const [stickers, setStickers] = useState<GiphyTypes.Gif[]>([]);

    const [isImagesExtended, setIsImagesExtended] = useState(false);
    const [isVideosExtended, setIsVideosExtended] = useState(false);
    const [isGifsExtended, setIsGifsExtended] = useState(false);
    const [isStickersExtended, setIsStickersExtended] = useState(false);

    const stockMediaService = StockMediaService.getInstance();
    const hasStockMediaApiKeys = stockMediaService.hasApiKeys();

    useEffect(() => {
        if (hasStockMediaApiKeys) {
            handleSearch("");
        }
    }, [hasStockMediaApiKeys]);

    const handleSelectImage = (data: PexelsTypes.Photo) => {
        return handleClickStock(data.src.original);
    };

    const handleSelectVideo = (data: PexelsTypes.Video) => {
        let videoUrl = data.video_files[0].link;
        let mimeType = data.video_files[0].file_type;
        let maxVideoResolution = -1;
        const canvasResolution = RendleyStore.display.width * RendleyStore.display.height;

        for (let i = 0; i < data.video_files.length; i++) {
            const videoResolution = (data.video_files[i].width ?? 1) * (data.video_files[i].height ?? 1);

            if (videoResolution > maxVideoResolution && videoResolution <= canvasResolution) {
                maxVideoResolution = videoResolution;
                videoUrl = data.video_files[i].link;
                mimeType = data.video_files[i].file_type;
            }
        }

        return handleClickStock(videoUrl, mimeType);
    };

    const handleSelectGif = (data: GiphyTypes.Gif) => {
        return handleClickStock(data.images.original.url, "image/gif");
    };

    const handleClickStock = async (url: string, mimeType?: string) => {
        try {
            const mediaDataId = await RendleyService.addMediaToGallery(url, mimeType);

            if (!mediaDataId) return;

            const media = RendleyService.getMediaById(mediaDataId);

            if (media) media.setPermanentUrl(url);

            const layer = RendleyService.createLayer();
            const clip = await RendleyService.addMediaToLayer(layer.id, mediaDataId);
            RendleyStore.setFilenameByClipId(clip.id, media.filename);
        } catch (error) {
            console.log("cannot add stock to canvas", error);
        }
    };

    const handleDebounceSearch = debounce((text: string) => {
        handleSearch(text);
    }, 1000);

    const handleSearch = async (text: string) => {
        setIsLoading(true);
        setHasError(false);

        setIsImagesExtended(false);
        setIsVideosExtended(false);
        setIsGifsExtended(false);
        setIsStickersExtended(false);

        try {
            const [imagesResponse, videosResponse, gifsResponse, stickersResponse] = await Promise.all([
                stockMediaService.searchImages(text),
                stockMediaService.searchVideos(text),
                stockMediaService.searchGifs(text),
                stockMediaService.searchStickers(text),
            ]);

            setImages(imagesResponse.photos);
            setVideos(videosResponse.videos);
            setGifs(gifsResponse.data);
            setStickers(stickersResponse.data);
        } catch {
            setHasError(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleExtendImages = () => setIsImagesExtended(true);
    const handleExtendVideos = () => setIsVideosExtended(true);
    const handleExtendGifs = () => setIsGifsExtended(true);
    const handleExtendStickers = () => setIsStickersExtended(true);

    if (!hasStockMediaApiKeys) {
        return (
            <InfoBanner
                icon={<ConfigError />}
                title="Missing stock media API keys!"
                message="Please ensure that you have provided the necessary API keys for stock media services. Check your configuration settings and add the required keys to continue."
            />
        );
    }

    const isResultEmpty = !images.length && !videos.length && !gifs.length && !stickers.length;

    return (
        <div style={{width: '100%', maxHeight: '600px', minWidth: "250px", paddingBottom: "40px"}}>
            <Searchbar onChangeText={(e) => handleDebounceSearch(e.target.value)}></Searchbar>

            {!isLoading && isResultEmpty ? (
                <InfoBanner
                    icon={<SearchIcon size={50}/>}
                    title="No Results Found"
                    message="Oops, we couldn’t find any matches. Try adjusting the search criteria or keywords to find what you're looking for."
                />
            ) : (
                <Fragment>
                    <div className="spacer-2"></div>

                    <PanelGridListSection
                        title="Images"
                        isLoading={isLoading}
                        data={images}
                        isExtended={isImagesExtended}
                        renderCard={(data: PexelsTypes.Photo) => (
                            <EffectShowcaseCard imageUrl={data.src.medium} onMouseDown={() => handleSelectImage(data)}/>
                        )}
                        onExtend={handleExtendImages}
                    />

                    <div className="spacer-2"></div>

                    <PanelGridListSection
                        title="Videos"
                        isLoading={isLoading}
                        data={videos}
                        isExtended={isVideosExtended}
                        renderCard={(data: PexelsTypes.Video) => (
                            <EffectShowcaseCard
                                imageUrl={data.image}
                                videoUrl={data.video_files[0].link}
                                onMouseDown={() => handleSelectVideo(data)}
                            />
                        )}
                        onExtend={handleExtendVideos}
                    />

                    <div className="spacer-2"></div>

                    {/*<PanelGridListSection*/}
                    {/*    title="GIFs"*/}
                    {/*    isLoading={isLoading}*/}
                    {/*    data={gifs}*/}
                    {/*    isExtended={isGifsExtended}*/}
                    {/*    renderCard={(data: GiphyTypes.Gif) => (*/}
                    {/*        <EffectShowcaseCard*/}
                    {/*            imageUrl={data.images.downsized_still.url}*/}
                    {/*            videoUrl={data.images.preview.mp4}*/}
                    {/*            onMouseDown={() => handleSelectGif(data)}*/}
                    {/*        />*/}
                    {/*    )}*/}
                    {/*    onExtend={handleExtendGifs}*/}
                    {/*/>*/}

                    <PanelGridListSection
                        title="Stickers"
                        isLoading={isLoading}
                        data={stickers}
                        isExtended={isStickersExtended}
                        renderCard={(data: GiphyTypes.Gif) => (
                            <EffectShowcaseCard
                                imageUrl={data.images.downsized_still.url}
                                videoUrl={data.images.preview.mp4}
                                onMouseDown={() => handleSelectGif(data)}
                            />
                        )}
                        onExtend={handleExtendStickers}
                    />

                    <div className="spacer-2"></div>
                </Fragment>
            )}
        </div>
    );
};

export default StockPanelContainer;
